import React, {Component} from 'react'
import {
    CAvatar,
    CDropdown,
    CDropdownHeader,
    CDropdownItem,
    CDropdownMenu,
    CDropdownToggle,
} from '@coreui/react'
import {
    cilLockLocked,
} from '@coreui/icons'
import CIcon from '@coreui/icons-react'

import avatar8 from './../../assets/images/avatar.jpg'
import AuthService from "../../services/auth.service";
import {Navigate} from "react-router-dom";

class AppHeaderDropdown extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: AuthService.getCurrentUser()
        }
    }

    logout() {
        AuthService.logout();
        this.setState({user: AuthService.getCurrentUser()});
    }

    render() {

        return (
            <CDropdown variant="nav-item">
                {!this.state.user && (
                    <Navigate to="/login" replace={true}/>
                )}
                <CDropdownToggle placement="bottom-end" className="py-0" caret={false}>
                    <CAvatar src={avatar8} size="md"/>
                </CDropdownToggle>
                <CDropdownMenu className="pt-0" placement="bottom-end">
                    <CDropdownHeader className="bg-light fw-semibold py-2">{this.state.user && this.state.user.username}</CDropdownHeader>
                    <CDropdownItem onClick={ () => this.logout()}>
                        <CIcon icon={cilLockLocked}  className="me-2"/>
                        Logout
                    </CDropdownItem>
                </CDropdownMenu>
            </CDropdown>
        )
    }
}

export default AppHeaderDropdown
