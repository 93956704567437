import React, {useEffect, useState} from 'react';
import {usePermission} from "react-permission-role";
import {Navigate} from "react-router-dom";


const AuthRoute = ({roles, children}) => {
        const {isAuthorized} = usePermission();
        const [authorized, setAuthorized] = useState(null);

        useEffect(() => {
                isAuthorized(roles).then(
                    (result) => {
                            setAuthorized(result);
                    }
                )
        }, [roles]);

        if (authorized === null) {
                return <></>
        } else if (authorized) {
                return children;
        } else {
                return  <Navigate to="/" replace/>;
        }

};

export default AuthRoute;