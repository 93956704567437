import React, {Component, Suspense} from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { CContainer, CSpinner } from '@coreui/react'

import routes from '../../routes'
import Toasts from "../notificacion/Toasts";
import Socket from "../socket/Socket";
import AuthRoute from "../route/AuthRoute";
import AuthService from "../../services/auth.service";

class Content extends Component {
    render() {

        const user = AuthService.getCurrentUser();

        return (
            <CContainer lg>
                <Socket/>
                <Toasts/>
                <Suspense fallback={<CSpinner color="primary" />}>
                    <Routes>
                        {routes.map((route, idx) => {
                            return (
                                route.element && (
                                    <Route
                                        key={idx}
                                        path={route.path}
                                        exact={route.exact}
                                        name={route.name}
                                        element={<AuthRoute roles={route.roles}><route.element /></AuthRoute>}
                                    />
                                )
                            )
                        })}
                        <Route path="/" element={<Navigate to={user.home} replace />} />
                    </Routes>
                </Suspense>
            </CContainer>
        )
    }
}

export default React.memo(Content)
