import React from "react";
import {CCard, CCardBody, CCol} from "@coreui/react";
import {DataGrid, esES, GridActionsCellItem, GridRowModes} from "@mui/x-data-grid";
import DataGridCrudBase from "../components/datagrid/DataGridCrudBase";
import Toasts from "../components/notificacion/Toasts";
import {StateContext} from "../components/context/StateProvider";
import EmployeesService from "../services/employees.service";
import Employee from "../models/Employee";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";


class Employees extends DataGridCrudBase {

    static contextType = StateContext;

    constructor(props) {
        super(props);
        this.state = {
            employeesModels: {},
            employees: [],
            modelGrid: 'employeesModels',
            stateGrid: 'employees',
            loading: true
        };
        this.apiRef = React.createRef();
        this.apiRef.current = {};
    }

    async componentDidMount() {

        const {state, updateState} = this.context;

        // Obtener rutas
        await EmployeesService.getEmployees().then(
            (data) => {
                const employeeMap = data.map((employee) => {
                    return new Employee(employee.id, employee.nombre)
                });

                this.setState({employees: employeeMap, loading: false});
            }
        ).catch((error) => {
            const toasts = Toasts.generateToast(error.message, 'error');
            updateState({toasts: [...state.toasts, toasts]})
        });
    }

    generateColumns() {
        const columns = [
            {
                field: 'id',
                headerName: 'Id',
                flex: 0.5,
                editable: false
            },
            {
                field: 'code',
                headerName: 'Código',
                flex: 0.5,
                type: 'number',
                editable: false
            },
            {
                field: 'name',
                headerName: 'Nombre',
                flex: 5,
                editable: true,
            },
            {
                field: 'actions',
                type: 'actions',
                headerName: '',
                width: 100,
                cellClassName: 'actions',
                getActions: ({id}) => {
                    const isInEditMode =
                        this.state.employeesModels[id]?.mode === GridRowModes.Edit;

                    if (isInEditMode) {
                        return [
                            <GridActionsCellItem
                                icon={<SaveIcon/>}
                                label="Save"
                                sx={{
                                    color: 'primary.main',
                                }}
                                onClick={() => this.handleSave(id)}
                            />,
                            <GridActionsCellItem
                                icon={<CancelIcon/>}
                                label="Cancel"
                                className="textPrimary"
                                onClick={() => this.handleGridCancel(id, this.state.stateGrid, this.state.modelGrid)}
                                color="inherit"
                            />,
                        ];
                    }

                    return [
                        <GridActionsCellItem
                            icon={<EditIcon/>}
                            label="Edit"
                            className="textPrimary"
                            onClick={() => this.handleGridEdit(id, this.state.stateGrid, this.state.modelGrid)}
                            color="inherit"
                        />,
                        <GridActionsCellItem
                            icon={<DeleteIcon/>}
                            label="Delete"
                            onClick={() => this.handleDelete(id)}
                            color="inherit"
                        />,
                    ];
                },
            },
        ];

        return columns;
    }

    /**
     * Guardar fila
     * @param id
     * @param modelGrid
     */
    handleSave = (id) => {

        const {state, updateState} = this.context;

        let row = this.apiRef.current.getRow(id);

        if (row.name === undefined || row.name === '') {
            const toasts = Toasts.generateToast('No se puede añadir empleado sin nombre', 'error');
            updateState({toasts: [...state.toasts, toasts]})
        } else {
            this.handleGridSave(id, this.state.modelGrid);
        }
    };

    /**
     * Editar fila
     * @param id
     * @param modelGrid
     */
    handleGridEdit = (id, stateGrid, modelGrid) => {

        this.clearGridRowEdit(stateGrid, modelGrid);

        this.setState({
            [modelGrid]: {
                ...this.state[modelGrid],
                [id]: {mode: GridRowModes.Edit},
            },
        });
    }

    /**
     * Cancelar de editar fila
     * @param id
     * @param stateGrid
     * @param modelGrid
     */
    handleGridCancel = (id, stateGrid, modelGrid) => {
        this.setState({
            [modelGrid]: {
                ...this.state[modelGrid],
                [id]: {mode: GridRowModes.View, ignoreModifications: true},
            },
        });

        const editedRow = this.state[stateGrid].find((row) => row.id === id);
        if (editedRow.isNew) {
            this.setState({
                [stateGrid]: this.state[stateGrid].filter((row) => row.id !== id),
            });
        }
    };

    /**
     * Gestiona el borrar etiqueta de la tabla
     * @param id
     * @returns {Promise<void>}
     */
    async handleDelete(id) {
        const {state, updateState} = this.context;
        const employee = this.state.employees.find((row) => row.id === id);

        await EmployeesService.deleteEmployee(employee.id).then(
            () => {
                const toasts = Toasts.generateToast('Se ha eliminado empleado correctamente', 'success');
                updateState({toasts: [...state.toasts, toasts]})
                this.handleGridDelete(id, this.state.stateGrid);
            }
        ).catch((error) => {
            const toasts = Toasts.generateToast(error.message, 'error');
            updateState({toasts: [...state.toasts, toasts]})
        });
    }

    /**
     * Genera boton para añadir a la lista
     * @returns {JSX.Element}
     */
    editToolbar = () => {
        return this.gridToolbar(this.state.stateGrid, this.state.modelGrid, 'Añadir empleado', 'name', () => {
            const id = Date.now();
            let employee = new Employee();
            employee.id = id;
            employee.isNew = true;
            return employee;
        });
    }

    /**
     * Gestiona cuando se actualiza fila de la tabla
     * @param newRow
     * @param rowOld
     * @returns {Promise<{boxes: [], delivery: *, address: *, agency: *, created: *, nameAgency: *, county: *, weight: number, isNew: boolean, packages: *, cp: *, nameDelivery: *, listCustomerAdress: [], phone: *, serie: *, name: *, id: *, state: *, order: *, customer: *}>}
     */
    processRowUpdate = async (newRow, rowOld) => {

        const employee = new Employee(newRow.id, newRow.name);
        return await this.processGridRowUpdate(newRow, rowOld, employee, this.state.stateGrid, this.state.modelGrid, async (stateGrid) => {

            const {state, updateState} = this.context;

            try {
                if (newRow.isNew) {
                    await EmployeesService.createEmployee(employee.name).then(
                        (data) => {
                            employee.id = data.data.id;
                            employee.code = data.data.id;
                            const toasts = Toasts.generateToast('Se ha creado empleado correctamente', 'success');
                            updateState({toasts: [...state.toasts, toasts]})
                        }
                    );
                } else {
                    await EmployeesService.updateEmployee(employee.id, employee.name).then(
                        () => {
                            const toasts = Toasts.generateToast('Se ha modificado empleado correctamente', 'success');
                            updateState({toasts: [...state.toasts, toasts]})
                        });
                }

                this.setState({
                    [stateGrid]: this.state[stateGrid].map((row) => (row.id === newRow.id ? employee : row))
                });

            } catch (error) {
                const toasts = Toasts.generateToast(error.message, 'error');
                updateState({toasts: [...state.toasts, toasts]})
            }
        });
    }

    handleRowEditStop = (params, event) => {
        this.handleGridRowEditStop(params, event);
    };

    handleRowEditStart = (params, event) => {
        this.clearGridRowEdit(this.state.stateGrid, this.state.modelGrid);
    }

    handleRowModesModelChange = (newRowModesModel) => {
        this.handleGridRowModesModelChange(newRowModesModel, this.state.modelGrid);
    };

    /**
     * Gestiona cuando se preciona enter cuando se esta añadiendo una etiqueta en la tabla
     * @param params
     * @param event
     * @returns {Promise<void>}
     */
    handleCellEditKeyDown = async (params, event) => {

        await this.handleGridCellEditKeyDown(params, event, async (row, event, params) => {
            row.order = event.target.value;
            row[params.field] = event.target.value;
            return true;
        }, this.handleSave);
    };

    render() {
        return (
            <CCol id="deliveries" md={12}>
                <CCard className="p-3">
                    <CCardBody>
                        <DataGrid
                            rows={this.state.employees}
                            columns={this.generateColumns()}
                            rowModesModel={this.state.employeesModels}
                            onRowEditStop={this.handleRowEditStop}
                            onRowEditStart={this.handleRowEditStart}
                            onRowModesModelChange={this.handleRowModesModelChange}
                            onCellKeyDown={this.handleCellEditKeyDown}
                            processRowUpdate={this.processRowUpdate}
                            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                            editMode="row"
                            apiRef={this.apiRef}
                            autoHeight={true}
                            slots={{
                                toolbar: this.editToolbar,
                            }}

                            initialState={{
                                columns: {
                                    columnVisibilityModel: {
                                        id: false,
                                    },
                                },
                            }}
                            loading={this.state.loading}
                        />
                    </CCardBody>
                </CCard>
            </CCol>
        );
    }
}

export default Employees;