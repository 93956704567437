import React, {useState} from 'react';
import useSalesOrder from "../hooks/useSalesOrder";
import {CCard, CCardBody, CCardHeader, CSpinner} from "@coreui/react";


const ListOrderInfo = (props) => {

    const {getSales} = useSalesOrder()
    const [loadingIndex, setLoadingIndex] = useState(null);

    const handleClick = async (order, index) => {
        setLoadingIndex(index);
        getSales(order, 1).then(() => {
                setLoadingIndex(null);
            }
        );
    }

    return (
        <>
            {props.listOrderInfo.length > 0 ?
                <CCard className="mb-3" id='list-order-info'>
                    <CCardHeader><strong>Albaranes</strong></CCardHeader>
                    <CCardBody>
                        <ul>
                            {props.listOrderInfo.map((order, index) => {
                                return <li onClick={() => handleClick(order, index)} key={index}>
                                    <span>{order}</span>
                                    {loadingIndex === index && <CSpinner className='ms-1' size="sm" />}
                                </li>
                            })}
                        </ul>
                    </CCardBody>
                </CCard>
                : ''}
        </>
    );
}

export default ListOrderInfo;