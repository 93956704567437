import axios from "axios";
import HostService from "./host.service";
import AuthService from "./auth.service";

class ConfigService {

    /**
     * Obtener configuracion por nombre
     * @param name
     * @returns {Promise<AxiosResponse<any>>}
     */
    static getConfig(name) {
        return axios.get(HostService.getHost() + 'configuracion/' + name, AuthService.setToken())
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log(error.message);
                throw error;
            });
    }

    /**
     * Crear configuracion
     * @param name
     * @returns {Promise<AxiosResponse<any>>}
     */
    static createConfig(name, value) {
        return axios.post(HostService.getHost() + 'configuracion', {nombre: name, valor: value}, AuthService.setToken())
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log(error.message);
                throw error;
            });
    }
}

export default ConfigService;