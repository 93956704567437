import React from "react";

import {CButton, CCard, CCardBody, CCardTitle, CForm, CFormInput} from "@coreui/react";
import InfoOrder from "../order/InfoOrder";
import OrderProduct from "../order/OrderProduct";
import Utils from "../../utils/Utils";
import Toasts from "../notificacion/Toasts";
import successSound from "../../assets/audio/success.mp3";
import errorSound from "../../assets/audio/error.mp3";
import warningSound from "../../assets/audio/warning.mp3";
import completeSound from "../../assets/audio/complete.mp3";
import {StateContext} from "../context/StateProvider";
import {DataGrid, esES, GridToolbar} from "@mui/x-data-grid";
import Filters from "../filter/Filters";
import OrderService from "../../services/order.service";
import VoiceUtils from "../../utils/VoiceUtils";

class ListOrder extends React.Component {

    static contextType = StateContext;

    constructor(props) {
        super(props);
        this.state = {ean: '', units: 1};
        this.eanRef = React.createRef();
        this.infoOrderRef = React.createRef();
        this.timer = null;
    }

    /**
     * Manejar cambios en unidades y ean
     * @param event
     * @param parametro
     */
    handleChange(event, parametro) {
        if (parametro === 'units') {
            this.setState({units: parseInt(event.target.value)});
        } else {
            this.setState({ean: event.target.value.replace(/\s/g, "")});
        }

        if (this.timer) {
            clearTimeout(this.timer);
        }

        this.timer = setTimeout(() => {
                if (parametro === 'units') {
                    this.eanRef.current.focus();
                }
            }
            , 500);
    }

    /**
     * Manejar submit
     * @param event
     * @param service
     * @returns {Promise<void>}
     */
    async handleSubmit(event, service) {

        if (event) {
            event.preventDefault();
        }

        if (this.state.ean !== "") {

            // Añadir productos
            await this.handleProductOrder(this.context, this.state.ean, this.state.units, service);

            this.setState({ean: ''});
            this.setState({units: 1});
        }

        this.eanRef.current.focus();
    }

    /**
     * Buscar todos los productos que correspondan con un ean
     * @param state
     * @param eanRef
     * @returns {*}
     */
    searchProductByEan(state, eanRef) {
        return state.products.map((product, index) => {
            const eanIndex = product.ean.findIndex((ean) => ean.BARRAS === eanRef);
            return eanIndex !== -1 ? {'index': index, 'units': product.ean[eanIndex].UNIDADES} : {
                'index': -1,
                'units': 0
            };
        }).filter((product) => product.index !== -1);
    }

    /**
     * Gestionar añadir productos a lista de ventas o de compras
     * @param context
     * @param eanRef
     * @param unitsRef
     * @param service
     * @returns {Promise<void>}
     */
    async handleProductOrder(context, eanRef, unitsRef, service) {

        const {state, updateState} = context;

        // Buscar todos los productos que tengan un codigo de barras
        const indexes = this.searchProductByEan(state, eanRef)

        if (indexes.length > 0) {

            for (let i = 0; i < indexes.length; i++) {

                let index = indexes[i].index;
                let units = (indexes[i].units > 1 ? indexes[i].units : unitsRef);

                const unitsRegister = state.products[index].register + units;

                if (state.products[index].remaining > 0 && state.products[index].units >= unitsRegister) {

                    let refArticulo = state.products[index].ref;

                    if (state.products[index].duplicate) {
                        refArticulo += '-' + state.products[index].line;
                    }

                    // TODO añadir al servicio de registar como en bultos
                    let registerDate = new Date();
                    await this.updateOrder(service, state, index, refArticulo, unitsRegister, registerDate, units, updateState, false, state.year);

                    break;

                } else if (state.products[index].remaining === 0 && i === indexes.length - 1) {
                    const toasts = Toasts.generateToast('Ya se han añadido todos los productos', 'warning');
                    updateState({toasts: [...state.toasts, toasts]})
                    Utils.playAudio(warningSound);
                } else if (unitsRegister > state.products[index].units && i === indexes.length - 1) {
                    const toasts = Toasts.generateToast('No puede registrar tantas unidades del producto', 'warning');
                    updateState({toasts: [...state.toasts, toasts]})
                    Utils.playAudio(warningSound);
                }
            }

        } else {
            const toasts = Toasts.generateToast('No existe producto en el pedido', 'error');
            updateState({toasts: [...state.toasts, toasts]})
            Utils.playAudio(errorSound);
        }
    }

    /**
     * Actualizar pedido
     * @param service
     * @param state
     * @param index
     * @param refArticulo
     * @param unitsRegister
     * @param registerDate
     * @param units
     * @param updateState
     * @param manual
     * @param year
     * @returns {Promise<void>}
     */
    updateOrder = async (service, state, index, refArticulo, unitsRegister, registerDate, units, updateState, manual, year) => {
        await service(
            state.products[index].order,
            refArticulo,
            unitsRegister,
            registerDate,
            manual,
            year,
            this.props.nave
        ).then(
            (data) => {
                state.products[index].remaining = state.products[index].remaining - units;
                if (state.products[index].remaining === 0) {
                    state.products[index].state = 1;
                }

                if (!state.products.some(item => item.remaining > 0)) {

                    updateState({filters: []})

                    // Si es movil suena un sonido sino habla
                    if (Utils.isMobile()) {
                        Utils.playAudio(completeSound);
                    } else {
                        VoiceUtils.speak('Pedido completado');
                    }

                    if (state.order.n2 && ((!this.props.orderStateN2 && state.order.stateN2 !== '3') || (this.props.orderStateN2 && state.order.state !== '3') )) {
                        // Cambiar el estado de la orden en nave 2 si se completa la orden y no se ha cambiado el estado de la nave 2 a preparado
                        OrderService.assignToOrder(state.order.num, null, null, this.props.handle, '3', state.order.n2, undefined, year, null).then(
                            () => {
                                if (this.props.orderStateN2) {
                                    state.order.state = '3';
                                } else {
                                    state.order.stateN2 = '3';
                                }
                                updateState({order: state.order});
                            }
                        ).catch((error) => {
                            const toasts = Toasts.generateToast(error.response?.data?.message, 'error');
                            updateState({toasts: [...state.toasts, toasts]})
                        });
                    }

                    if (this.props.handle === 'ventas' && !this.props.orderStateN2) {
                        // Cambiar estado de pedido
                        OrderService.assignToOrder(state.order.num, null, null, this.props.handle, '3', null, undefined, state.year, null).then(
                            () => {
                                state.order.state = '3';
                                updateState({order: state.order});
                            }
                        ).catch((error) => {
                            const toasts = Toasts.generateToast(error.response?.data?.message, 'error');
                            updateState({toasts: [...state.toasts, toasts]})
                        });
                    }
                }

                state.products[index].register = unitsRegister;
                state.products[index].manual = manual;
                state.products[index].registerDate = Utils.formatDate(registerDate);
                state.order.setLastUpdated(registerDate);
                const toasts = Toasts.generateToast('Producto escaneado: <strong>ubicación = ' + state.products[index].location + '</strong>' + (state.products[index].serie ? ' // <strong class="text-danger">Producto con serie</strong>' : ''), 'success');
                updateState({toasts: [...state.toasts, toasts]})
                Utils.playAudio(successSound);
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                const toasts = Toasts.generateToast(resMessage, 'success');
                updateState({toasts: [...state.toasts, toasts]})
                Utils.playAudio(errorSound);
            })
    }

    componentWillUnmount() {
        const {updateState} = this.context;
        if (this.timer) {
            clearTimeout(this.timer);
        }
        updateState({filters: []});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        const {state} = this.context;

        if (state.order.preparer === '0' || state.order.reviewer === '0' || state.order.camera === null || state.order.camera === '' ) {
            this.infoOrderRef.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            });
        } else if (prevState.units === this.state.units && prevProps.order === this.props.order && this.props.order !== '' && state.order.editing === false) {
            this.eanRef.current?.focus();
        }
    }

    /**
     * Definición columnas de la tabla
     * @returns {[{headerName: string, field: string, flex: number},{headerName: string, field: string, flex: number},{headerName: string, field: string, flex: number},{headerName: string, field: string, flex: number},{headerName: string, field: string, flex: number},null,null]}
     */
    generateColumns() {
        const columns = [
            {
                field: 'ref',
                headerName: 'Referencia',
                flex: 1,
            },
            {
                field: 'ean',
                headerName: 'Cod. Barras',
                flex: 1,
            },
            {
                field: 'name',
                headerName: 'Descripción',
                flex: 1,
            },
            {
                field: 'units',
                headerName: 'Unidades',
                flex: 1,
            },
            {
                field: 'location',
                headerName: 'Ubicación',
                flex: 1,
            },
            {
                field: 'state',
                headerName: 'Estado',
                flex: 1,
            },
            {
                field: 'serie',
                headerName: 'Serie',
                flex: 1,
            },
        ];

        if (!this.props.read) {
            columns.push({
                field: 'registerDate',
                headerName: 'Registrado',
                flex: 1,
            });
        }

        return columns;
    }

    filterList(value) {
        const {updateState} = this.context;
        if (value === '3') {
            updateState({filters: []})
        } else {
            updateState({filters: [{field: 'state', operator: 'equals', value: (parseInt(value) - 1).toString()}]})
        }
    }

    render() {

        const {state} = this.context;
        const {type, handle, registerAction, read, preparer, reviewer, camera, orderStateN2} = this.props;
        const _this = this;
        const columns = this.generateColumns();

        return (
            <div>
                {state.products.length > 0 ?
                    <div>
                        <CCard>
                            <CCardBody className='p-0'>
                                <div ref={this.infoOrderRef}>
                                    <InfoOrder type={type} order={handle} read={read} preparer={preparer}
                                               reviewer={reviewer} orderStateN2={orderStateN2} camera={camera}/>
                                </div>
                                {((state.order.preparer !== '0' && state.order.reviewer !== '0' && handle === 'compras')
                                    || (state.order.preparer !== '0' && state.order.reviewer !== '0' && ((camera && state.order.camera !== null && state.order.camera !== '') || !camera) && handle === 'ventas')
                                    || state.order.complete
                                    || (orderStateN2 && state.order.preparer !== '0' && state.order.state !== '0' && handle === 'compras')
                                    || (orderStateN2 && state.order.preparer !== '0' && state.order.state !== '0' && ((camera && state.order.camera !== null && state.order.camera !== '') || !camera) && handle === 'ventas')
                                ) ?
                                    <>
                                        {!state.order.complete && !read ?
                                            <CCard className='border-0 rounded-0 border-top'>
                                                <CCardBody>
                                                    <CCardTitle
                                                        className="mb-3">{`Puntear ${type}`.toUpperCase()}</CCardTitle>
                                                    <CForm
                                                        onSubmit={(e) => this.handleSubmit(e, registerAction)}
                                                        className="mb-3">
                                                        <div className="mb-3">
                                                            <label htmlFor="units"
                                                                   className="form-label">Unidades</label>
                                                            <CFormInput className="form-control" type="number"
                                                                        id="units"
                                                                        name="units"
                                                                        min="1"
                                                                        value={this.state.units}
                                                                        onChange={(event) => this.handleChange(event, 'units')}/>
                                                        </div>
                                                        <div className="mb-3">
                                                            <label htmlFor="purchase-scan" className="form-label">Escanear
                                                                código de
                                                                barras</label>
                                                            <CFormInput ref={this.eanRef} className="form-control"
                                                                        type="text"
                                                                        id="purchase-scan"
                                                                        name="purchase-scan"
                                                                        value={this.state.ean}
                                                                        onChange={(event) => this.handleChange(event, 'ean')}/>
                                                        </div>
                                                        <CButton type="submit" color="primary" className="px-4 col-12">
                                                            Añadir
                                                        </CButton>
                                                    </CForm>
                                                </CCardBody>
                                            </CCard>
                                            : ''}

                                        <div id='list-order'>
                                            {!read && !state.order.complete ?
                                                <Filters productStates={true} filter={(value) => this.filterList(value)}/>
                                                : ''}
                                            <DataGrid
                                                rows={state.products}
                                                columns={columns}
                                                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                                                disableRowSelectionOnClick={true}
                                                autoHeight={true}
                                                disableVirtualization={true}
                                                filterModel={{
                                                    items: state.filters
                                                }}
                                                initialState={{
                                                    columns: {
                                                        columnVisibilityModel: {
                                                            state: false,
                                                            serie: false
                                                        },
                                                    },
                                                }}
                                                slots={
                                                    {
                                                        row: OrderProduct,
                                                        // toolbar: GridToolbar
                                                    }
                                                }
                                                slotProps={
                                                    {
                                                        row: {
                                                            read: read,
                                                            updateOrderAction: _this.updateOrder,
                                                            registerOrderAction: registerAction
                                                        },
                                                        // toolbar: {
                                                        //     showQuickFilter: true,
                                                        //     quickFilterProps: { debounceMs: 500 },
                                                        // },
                                                    }
                                                }
                                            />
                                        </div>
                                    </>
                                    : ''}
                            </CCardBody>
                        </CCard>
                    </div>
                    : ''}
            </div>
        );
    }
}

export default ListOrder;