import axios from "axios";
import HostService from "./host.service";
import AuthService from "./auth.service";


class OrderService {

    /**
     * Asignar revisor y preparador
     * @param order
     * @param reviewer
     * @param preparer
     * @returns {Promise<AxiosResponse<any>>}
     */
    static assignToOrder(order, reviewer, preparer, type, state, n2, exclude, year, camera) {

        let subPath = 'asignar';

        if (n2) {
            subPath = 'n2/' + subPath;
        }

        let data = {};
        if (preparer) {
            data.preparado = (preparer === '0' ? null : preparer);
        } else if (camera !== null) {
            data.camara = camera;
        } else if (reviewer) {
            data.revisado = (reviewer === '0' ? null : reviewer);
        }

        if (typeof exclude !== 'undefined') {
            data.excluir= exclude;
        }

        if (state) {
            data.estado = state;
        }

        return axios.post(HostService.getHost() + type +'/'+subPath+'?pedido='+order+'&ejercicio='+year, data, AuthService.setToken())
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log(error.message);
                throw error;
            });
    }

    /**
     * Obtener pedido n2
     * @param order
     * @returns {Promise<AxiosResponse<any>>}
     */
    static getStateOrderN2(order, year) {
        return axios.get(HostService.getHost() + 'ventas/n2/estado?pedido='+order+'&ejercicio='+year, AuthService.setToken())
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log(error.message);
                throw error;
            });
    }

    /**
     * Obtener cliente de albaran
     * @param order
     * @returns {Promise<AxiosResponse<any>>}
     */
    static getCustomerOrder(serie, order) {
        return axios.get(HostService.getHost() +'cliente/'+serie+'/'+order, AuthService.setToken())
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log(error.message);
                throw error;
            });
    }

    /**
     * Obtener personas asigndas a un pedido
     * @param order
     * @returns {Promise<AxiosResponse<any>>}
     */
    static getPersonsOrder(order, type, year) {
        return axios.get(HostService.getHost() + type +'/personas?pedido='+order+'&ejercicio='+year, AuthService.setToken())
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log(error.message);
                throw error;
            });
    }

    /**s
     * Comprobar si tiene pedido en nave 2
     * @param order
     * @returns {Promise<AxiosResponse<any>>}
     */
    static checkOrderN2(order, year) {
        return axios.get(HostService.getHost() + 'ventas/n2/comprobar?pedido='+order+'&ejercicio='+year, AuthService.setToken())
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log(error.message);
                throw error;
            });
    }
}

export default OrderService;