import AuthService from "../services/auth.service";
import React, {Component} from "react";
import {Navigate} from "react-router-dom";
import {cilLockLocked, cilUser} from '@coreui/icons'
import {
    CButton,
    CCard,
    CCardBody, CCardGroup,
    CCol,
    CContainer, CForm,
    CFormInput,
    CInputGroup,
    CInputGroupText,
    CRow, CSpinner
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import {StateContext} from "../components/context/StateProvider";
import logo from "../assets/brand/logo.png";
import Toasts from "../components/notificacion/Toasts";
import withPermission from "../components/hooks/withPermission";
import Roles from "../config/roles";

class Login extends Component {

    static contextType = StateContext;

    constructor(props) {
        super(props);
        this.state = {
            username: "",
            password: "",
            usernameError: false,
            passwordError: false,
            user: AuthService.getCurrentUser()
        }
    }

    onChangeUsername = (e) => {
        this.setState({username: e.target.value});
    };

    onChangePassword = (e) => {
        this.setState({password: e.target.value});
    };

    componentDidMount() {
        const {updateState} = this.context;
        updateState({toasts: []})
    }

    handleLogin = (e) => {
        e.preventDefault();

        const {state, updateState} = this.context;
        let messagesError = [];

        this.setState({usernameError: false});
        this.setState({passwordError: false});
        updateState({loading: true});

        let isValid = true;

        if (this.state.username.trim() === '') {
            this.setState({usernameError: true});
            const toasts = Toasts.generateToast('Usuario es obligatorio', 'error');
            messagesError.push(toasts);
            isValid = false;
        }

        if (this.state.password.trim() === '') {
            const toasts = Toasts.generateToast('Password es obligatorio', 'error');
            messagesError.push(toasts);
            this.setState({passwordError: true});
            isValid = false;
        }

        if (isValid) {
            AuthService.login(this.state.username, this.state.password).then(
                (data) => {
                    updateState({loading: false});
                    updateState({toasts: []});
                    if (data.username) {
                        const user = {
                            id: data.id,
                            username: data.username,
                            token: data.token,
                            roles: [Roles[data.rol].role],
                            home: Roles[data.rol].home
                        };
                        this.props.setUser(user);
                        this.setState({user: user});
                    }
                },
                (error) => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    updateState({loading: false});
                    const toasts = Toasts.generateToast(resMessage, 'error');
                    updateState({toasts: [...state.toasts, toasts]});
                }
            );
        } else {
            updateState({toasts: [...state.toasts, ...messagesError]});
            updateState({loading: false});
        }
    };

    render() {

        const {state} = this.context;

        return (
            <>
                {this.state.user ? <Navigate to={this.state.user.home} replace={true}/>
                    :
                    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
                        <CContainer>
                            <Toasts/>
                            <CRow className="justify-content-center">
                                <CCol md={8}>
                                    <CCardGroup>
                                        <CCard className="p-4">
                                            <CCardBody>
                                                <CForm onSubmit={(e) => this.handleLogin(e)}>
                                                    <h1>Login</h1>
                                                    <p className="text-medium-emphasis">Almacén Mandatelo</p>
                                                    <CInputGroup className="mb-3">
                                                        <CInputGroupText>
                                                            <CIcon icon={cilUser}/>
                                                        </CInputGroupText>
                                                        <CFormInput placeholder="Usuario" autoComplete="usuario"
                                                                    onChange={(e) => this.onChangeUsername(e)}
                                                                    invalid={this.state.usernameError !== false}/>
                                                    </CInputGroup>
                                                    <CInputGroup className="mb-4">
                                                        <CInputGroupText>
                                                            <CIcon icon={cilLockLocked}/>
                                                        </CInputGroupText>
                                                        <CFormInput
                                                            type="password"
                                                            placeholder="Password"
                                                            autoComplete="current-password"
                                                            onChange={(e) => this.onChangePassword(e)}
                                                            invalid={this.state.passwordError !== false}
                                                        />
                                                    </CInputGroup>
                                                    <CRow>
                                                        <CCol xs={6}>
                                                            <CButton type="submit" color="primary" className="px-4">
                                                                {state.loading ?
                                                                    <CSpinner component="span" size="sm"
                                                                              aria-hidden="true"/> : 'Login'}
                                                            </CButton>

                                                        </CCol>
                                                    </CRow>
                                                </CForm>
                                            </CCardBody>
                                        </CCard>
                                        <CCard className="text-white bg-primary py-5" style={{width: '100%'}}>
                                            <CCardBody className="text-center d-flex justify-content-center">
                                                <img src={logo} alt="Mandatelo" className="img-fluid m-4"/>
                                            </CCardBody>
                                        </CCard>
                                    </CCardGroup>
                                </CCol>
                            </CRow>
                        </CContainer>
                    </div>
                }
            </>
        )
    }
}

export default withPermission(Login);