import React from "react";
import withRouter from "../components/withRouter";
import {StateContext} from "../components/context/StateProvider";
import ProductService from "../services/product.service";
import {CCard, CCardBody, CCol} from "@coreui/react";
import Badge from "react-bootstrap/Badge";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";


class Rmas extends React.Component {

    static contextType = StateContext;

    constructor(props) {
        super(props);
        this.state = {value: '', providers: [], products: [], show: false, current_prov: '', warehouse: null};
    }

    async componentDidMount() {
        const warehouse = this.props.params.warehouse;

        this.setState({warehouse: warehouse});

        await ProductService.getProviderByWarehouseRma(warehouse).then(
            (data) =>
                this.setState({providers: data})
        );
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.params.warehouse !== this.props.params.warehouse) {
            this.setState({warehouse: this.props.params.warehouse});

            await ProductService.getProviderByWarehouseRma(this.props.params.warehouse).then(
                (data) =>
                    this.setState({providers: data})
            );
        }
    }

    async getProductProviderByWarehouseRma(codigo) {
        console.log(codigo + ' ' + this.state.warehouse);
        await ProductService.getProductProviderByWarehouseRma(this.state.warehouse, codigo).then(
            (data) =>
                this.setState({products: data})
        );
    }

    handleShow(e) {
        let codigo = e.target.id.split("_");
        if (codigo[1] !== 'null') {
            this.getProductProviderByWarehouseRma(codigo[1]);
            this.setState({show: true, current_prov: e.target.innerText, products: []});
        }
    }

    handleClose() {
        this.setState({show: false});
    }

    render() {

        return (
            <CCol id="rmas" md={12}>
                <CCard>
                    <CCardBody>
                        <ul className="prov-list p-0 mb-0 d-flex justify-content-center">
                            {this.state.providers.map(function (d, idx) {
                                let nombre = d.NOMBRE ? d.NOMBRE.toUpperCase() : 'SIN PROVEEDOR';
                                return (
                                    <li className="col-4 mt-2 mb-2"
                                        key={idx}>
                                        <h6 className="prov-title mt-2" id={`nm_${d.PROVEEDOR}`}
                                            onClick={(e)=>this.handleShow(e)}>{nombre}</h6>
                                        <Badge bg="primary" className="mb-2">{d.TOTAL}</Badge>
                                    </li>
                                )
                            }.bind(this))}
                        </ul>
                    </CCardBody>
                </CCard>

                <Modal show={this.state.show} onHide={()=>this.handleClose()}>
                    <Modal.Header closeButton>
                        <Modal.Title>{this.state.current_prov}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="p-0">
                        <ul className="item-list p-0 mb-0">
                            {this.state.products.map(function (d, idx) {
                                return (
                                    <li className="pt-2 pb-2 pe-3 ps-3" key={d.CODIGO}>
                                        <h5 className="item-ref">{d.ARTICULO}</h5>
                                        <h6 className="item-name mb-0">{d.NOMBRE}
                                            <Badge bg="primary">{d.FINAL}</Badge>
                                        </h6>
                                    </li>
                                )
                            })}
                        </ul>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={()=>this.handleClose()}>
                            Cerrar
                        </Button>
                    </Modal.Footer>
                </Modal>
            </CCol>
        )
    }
}

export default withRouter(Rmas);