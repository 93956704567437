import React from "react";
import DeliveryService from "../services/delivery.service";
import Toasts from "../components/notificacion/Toasts";
import {StateContext} from "../components/context/StateProvider";
import {CCard, CCardBody, CCardTitle, CCol} from "@coreui/react";
import Utils from "../utils/Utils";
import {CropFree, StickyNote2, Today, Visibility} from "@mui/icons-material";


class PanelDelivery extends React.Component {

    static contextType = StateContext;

    constructor(props) {
        super(props);
        this.state = {deliveries: []};
    }

    componentDidMount() {
        // Obtener rutas
        DeliveryService.getDeliveries().then(
            (data) => {
                this.setState({deliveries: data});
            }
        ).catch((error) => {
            const toasts = Toasts.generateToast(error.message, 'error');
            this.context.updateState({toasts: [...this.context.state.toasts, toasts]})
        });
    }


    render() {

        const {deliveries} = this.state;

        return (
            <CCol id="deliveries" md={12}>
                <div className="row">
                    {deliveries.map(function (d, idx) {
                        return (
                            <CardDelivery key={idx} d={d}/>
                        )
                    })}
                </div>
            </CCol>
        )
    }

}

export default PanelDelivery;


class CardDelivery extends React.Component {

    render() {

        const {d} = this.props;

        return (
            <>
                {
                    d.panel ? <CCard className="pt-4 pb-4 pe-5 ps-5 col-md-3 mb-3 ms-2 me-2">
                        <CCardTitle className="text-center xtmt-3 ms-3 me-3"><a
                            href={'#/panel/etiquetas/' + d.name + '?id=' + d.id}
                            onClick={(e) => e.stopPropagation()}>{d.name}</a>
                        </CCardTitle>
                        <CCardBody className="text-center p-0 mt-2">
                            <p className="card-text">{Utils.formatDateWithoutHour(Date.parse(d.date))}</p>

                            <div className='row mb-3'>
                                <div className='col-12'>
                                    <a
                                        href={'#/panel/etiquetas/' + d.name + '?id=' + d.id}
                                        onClick={(e) => e.stopPropagation()}><StickyNote2/></a>
                                    <a className='ms-2'
                                       href={'#/panel/etiquetas/' + d.name + '?id=' + d.id + '&view=true'}
                                       onClick={(e) => e.stopPropagation()}><Visibility/></a>
                                    <a className='ms-2'
                                       href={'#/panel/rutas/' + d.id}
                                       onClick={(e) => e.stopPropagation()}><CropFree/></a>
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-12 d-inline-flex align-items-center">
                                    <label htmlFor="preparer"
                                           className="form-label mb-1 me-2">Sage:</label>
                                    <div className="form-text mt-0 mb-1">{d.counter}</div>
                                </div>
                                <div className="col-12 d-inline-flex align-items-center">
                                    <label htmlFor="preparer"
                                           className="form-label mb-1 me-2">Bultos:</label>
                                    <div className="form-text mt-0 mb-1">{d.packages}</div>
                                </div>
                                <div className="col-12 d-inline-flex align-items-center">
                                    <label htmlFor="preparer"
                                           className="form-label mb-1 me-2">Expediciones:</label>
                                    <div className="form-text mt-0 mb-1">{d.expedition}</div>
                                </div>
                                {d.barito ?
                                    <div className="col-12 d-inline-flex align-items-center">
                                        <label htmlFor="preparer"
                                               className="form-label mb-1 me-2">M3:</label>
                                        <div className="form-text mt-0 mb-1">{d.weight}</div>
                                    </div>
                                    : ''}
                            </div>
                        </CCardBody>
                    </CCard> : ''
                }
            </>
        );
    }
}