import React from "react";
import Toasts from "../notificacion/Toasts";
import {StateContext} from "../context/StateProvider";
import AsignaService from "../../services/asigna.service";
import HandleExceptions from "../../exceptions/HandleExceptions";
import StickerService from "../../services/sticker.service";


class PrintStickerAsigna extends React.Component {

    static contextType = StateContext;

    constructor(props) {
        super(props);
        this.iframRef = React.createRef();
        this.state = {
            url: null,
        };
    }

    async printSticker(sticker) {

        const {state, updateState} = this.context;

        try {
            if (sticker.cp !== '' && sticker.address !== '' && sticker.name !== '') {

                if (sticker.expedicionAsigna === null || sticker.expedicionAsigna === '') {
                    const resultRegisterDelivery = await AsignaService.registerDelivery(sticker);
                    if (resultRegisterDelivery.data) {
                        sticker.expedicionAsigna = resultRegisterDelivery.data.exp;
                        state.toasts.push(Toasts.generateToast('Generada etiqueta en asigna', 'success'));
                        // Guardar en servidor exp en etiqueta
                        await StickerService.registerSticker(sticker).then(
                            async () => {
                                state.toasts.push(Toasts.generateToast('Expedición asignada a etiqueta', 'success'));
                            }
                        );
                    } else {
                        HandleExceptions.throwException("Error generando etiqueta en asigna:" + resultRegisterDelivery.message);
                    }
                }

                const resultGetSticker = await AsignaService.getSticker(window.env.ASIGNA_PREFIX+sticker.id);
                if (resultGetSticker.data) {
                    const base64String = resultGetSticker.data;
                    const decodedBytes = atob(base64String);
                    const byteNumbers = new Array(decodedBytes.length);
                    for (let i = 0; i < decodedBytes.length; i++) {
                        byteNumbers[i] = decodedBytes.charCodeAt(i);
                    }
                    const byteArray = new Uint8Array(byteNumbers);
                    const blob = new Blob([byteArray], {type: 'application/pdf'});
                    const urlObject = URL.createObjectURL(blob);
                    this.setState({url: urlObject});
                } else {
                    HandleExceptions.throwException("Error obteniendo etiqueta en asigna:" + resultGetSticker.message);
                }

            } else {
                HandleExceptions.throwException("Se necesita rellenar todos los campos de la dirección para generar etiqueta de asigna");
            }

            updateState({toasts: state.toasts})

        } catch (error) {
            this.setState({url: null});
            updateState({toasts: [...state.toasts, Toasts.generateToast(error.message, 'error')]})
        }
    }

    handleIframeLoad() {
        if (this.state.url !== null) {
            const iframeWindow = this.iframRef.current.contentWindow;
            iframeWindow.print();
        }
    };

    render() {
        const {url} = this.state;
        return (
            <iframe className='hidden' ref={this.iframRef} src={url}/>
        );
    }
}

export default PrintStickerAsigna;