import {format} from "date-fns";
import {MOSTRADOR} from "../config/constants";


class Utils {
    /**
     * Formatear date
     * @param date
     * @returns {string}
     */
    static formatDate(date) {
        return format(date, 'dd/MM/yyyy HH:mm:ss');
    }

    /**
     * Formatear date
     * @param date
     * @returns {string}
     */
    static formatDateWithoutHour(date) {
        if (date) {
            return format(date, 'dd/MM/yyyy');
        }

        return '';
    }

    /**
     * Primera letra en mayuscula
     * @param string
     * @returns {string}
     */
    static toCapitalize(string) {
        if (string && string.length > 0)
            return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()

        return string;
    }

    /**
     * Comprobar si es un producto especial
     * @param refProduct
     * @returns {*}
     */
    static checkRefProduct(refProduct) {
        return window.env.REACT_APP_VENTAS_PRODUCTOS_EXCLUIR.split(',').includes(refProduct.replace(/\d+/g, ''));
    }

    /**
     * Comprobar si estamos en un movil o ordenador
     * @returns {boolean}
     */
    static isMobile() {
        const userAgent = window.navigator.userAgent.toLowerCase();
        return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);
    }

    /**
     * Ejecutar sonido
     * @param sound
     */
    static playAudio(sound) {
        const audio = new Audio(sound);
        audio.play();
    }

    /**
     * Comprobar que valor no es nulo ni esta undefinied
     * @param value
     * @returns {boolean}
     */
    static isEmpty(value) {
        return value === null || value === undefined;
    }

    static isFullEmpty(value) {
        return value === null || value === undefined || value === '';
    }

    /**
     * Obtener año actual
     * @returns {number}
     */
    static getCurrentYear() {
        return (new Date()).getFullYear();
    }

    /**
     * Generar array de años
     * @returns {[]}
     */
    static getArrayYears() {
        const years = [];
        for (let year = Utils.getCurrentYear(); year >= 2023; year--) {
            years.push({label: year, value: (year === Utils.getCurrentYear() ? '' : year)});
        }
        return years;
    }

    /**
     * Comprobar si tiene serie y albaran
     * @param order
     * @returns {boolean}
     */
    static checkOrder(order) {
        return order.split('-').length === 2;
    }

    /**
     * Obtener orden en serie y numero
     * @param order
     * @returns {{serie: *, order: *}}
     */
    static getOrder(order){
        const orderSplit = order.split('-');
        return {serie: orderSplit[0], order: orderSplit[1]}
    }

    /**
     * Ver si transporte esta en los que son mostrador
     * @param transport
     * @returns {string}
     */
    static getTransport(transport) {
        const transportNormalize = transport?.trim()?.toLowerCase();
        const transports = MOSTRADOR.map(ruta => ruta.trim().toLowerCase());
        return transports.includes(transportNormalize);
    }
}

export default Utils;