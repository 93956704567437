import React from 'react';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';

const withRouter = WrappedComponent => props => {
    const params = useParams();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    return (
        <WrappedComponent
            {...props}
            params={params}
            search={searchParams}
            navigate={navigate}
        />
    );
};

export default withRouter;