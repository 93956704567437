import {StateContext} from "../context/StateProvider";

const React = require("react");
import {ArrowBack, ArrowForward, StickyNote2} from "@mui/icons-material";
import DeliveryService from "../../services/delivery.service";
import Toasts from "../notificacion/Toasts";


class DeliveryNavigate extends React.Component {

    static contextType = StateContext;

    constructor(props) {
        super(props);
        this.state = {deliveries: [], routes: []};
    }

    componentDidMount() {
        // Obtener rutas
        DeliveryService.getDeliveries().then(
            (data) => {
                this.setState({deliveries: data});
            }
        ).catch((error) => {
            this.context.updateState({toasts: [...this.context.state.toasts, Toasts.generateToast(error.message, 'error')]})
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (JSON.stringify(prevProps) !== JSON.stringify(this.props) && Object.keys(this.props.delivery).length !== 0) {
            this.setState({routes: this.searchNexBackDelivery(this.props.delivery.id)});
        }
    }

    getPath (type, name, id) {
        switch (type) {
            case 'sticker':
                return '#/panel/etiquetas/' + name + '?id=' + id;
            case 'view':
                return '#/panel/etiquetas/' + name + '?id=' + id + '&view=true';
            default:
                return '#/panel/rutas/' + id;
        }
    }

    searchNexBackDelivery(id) {
        const { deliveries } = this.state;
        const index = deliveries.findIndex(delivery => delivery.id === id);

        if (index === -1) {
            return [null, null]; // Si el elemento no se encuentra, devolver [null, null]
        }

        const prevDelivery = index > 0 ? deliveries[index - 1] : null;
        const nextDelivery = index < deliveries.length - 1 ? deliveries[index + 1] : null;

        if (prevDelivery) {
            prevDelivery.path = this.getPath(this.props.page, prevDelivery.name, prevDelivery.id);
        }

        if (nextDelivery) {
            nextDelivery.path = this.getPath(this.props.page, nextDelivery.name, nextDelivery.id);
        }

        return [prevDelivery, nextDelivery];
    }

    render() {

        const {routes} = this.state;

        return (
            <div className='mb-2'>
                {routes[0] ?
                    <a
                        href={routes[0]?.path}
                        ><ArrowBack/></a>
                    : ''}
                {routes[1] ?
                    <a className='float-end'
                        href={routes[1]?.path}
                        ><ArrowForward/></a>
                : ''}
            </div>
        );
    }
}

export default DeliveryNavigate;