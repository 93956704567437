import React, {useState, useContext, useEffect} from 'react';
import {CCard, CCardTitle, CCol} from "@coreui/react";
import {StateContext} from "../components/context/StateProvider";
import SalesService from "../services/sales.service";
import Utils from "../utils/Utils";
import OrderStates from "../utils/OrderStates";
import ConfigService from "../services/config.service";
import {CONFIG_MARQUESINA} from "../config/constants";


const StateOrdersTv = () => {

    const {state, updateState} = useContext(StateContext);
    const [deliveries, setDeliveries] = useState([]);
    const [time, setTime] = useState(new Date());
    const [marquee, setMarquee] = useState(null);

    useEffect(() => {

        const intervalTime = setInterval(() => {
            setTime(new Date());
        }, 1000);

        const fetchDeliveries = () => {
            SalesService.getDeliveryStates(state.year).then((data) => {
                setDeliveries(data);
            });
        }

        const fetchMarque = () => {
            ConfigService.getConfig(CONFIG_MARQUESINA).then((data) => {
                setMarquee(data.value);
            });
        }

        fetchDeliveries();
        fetchMarque();

        const intervalDeliveries = setInterval(fetchDeliveries, 30000);
        const intervalMarquee = setInterval(fetchDeliveries, 30000);

        return () => {
            clearInterval(intervalDeliveries);
            clearInterval(intervalMarquee);
            clearInterval(intervalTime);
        }


    }, [state.year]);

    return (
        <CCol id="state-orders-tv" md={12}>
            <div className="row justify-content-center">
                {deliveries.map(function (delivery, idx) {
                    return (
                        <CardDeliveryStatus key={idx} delivery={delivery}/>
                    )
                })}
                <div className="time text-center">{time.toLocaleTimeString()}</div>
                {marquee !== null ?
                    <div className="marquee mt-4 col-12">
                        <span>{marquee}</span>
                    </div>
                    : ''}
            </div>
        </CCol>
    )
}

const CardDeliveryStatus = (props) => {

    const {delivery} = props;

    return (
        <CCard className="pt-4 ps-4 pe-4 col-md-3 mb-3 ms-2 me-2">
            <CCardTitle className="text-center xtmt-3 ms-3 me-3">{Utils.toCapitalize(delivery.routeName)}</CCardTitle>
            <div className='row states-count'>
                <div className="col-12 d-inline-flex align-items-center">
                    <label htmlFor="preparer"
                           className="form-label mb-1 me-2">{OrderStates.states[0]}:</label>
                    <div className="mt-0 mb-1">{delivery.unpreparedCount}</div>
                </div>
                <div className="col-12 d-inline-flex align-items-center">
                    <label htmlFor="preparer"
                           className="form-label mb-1 me-2">{OrderStates.states[1]}:</label>
                    <div className="mt-0 mb-1">{delivery.preparedCount}</div>
                </div>
                <div className="col-12 d-inline-flex align-items-center">
                    <label htmlFor="preparer"
                           className="form-label mb-1 me-2">{OrderStates.states[3]}:</label>
                    <div className="mt-0 mb-1">{delivery.reviewingCount}</div>
                </div>
                <div className="col-12 mt-2 mb-4 d-inline-flex align-items-center text-center justify-content-center">
                    <div className="mt-0 col-4 total">{delivery.total}</div>
                </div>
            </div>
        </CCard>
    );
}

export default StateOrdersTv;