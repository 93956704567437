import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {CFormCheck, CFormInput, CFormSelect, CModalTitle} from "@coreui/react";
import Utils from "../../utils/Utils";

class Address extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            sticker: props.sticker || {
                name: '',
                address: '',
                cp: '',
                county: '',
                state: '',
                phone: '',
                listCustomerAdress: [],
            },
            addresses: [],
        };
    }

    handleClose() {
        this.props.handleClose();
    }

    handleConfirmation() {
        this.props.handleConfirm(this.state.sticker);
    }

    handleChange(event, type) {
        if (type === 'name') {
            this.state.sticker.name = event.target.value;
        } else if (type === 'address') {
            this.state.sticker.address = event.target.value;
        } else if (type === 'cp') {
            this.state.sticker.cp = event.target.value;
        } else if (type === 'county') {
            this.state.sticker.county = event.target.value;
        } else if (type === 'state') {
            this.state.sticker.state = event.target.value;
        } else if (type === 'phone') {
            this.state.sticker.phone = event.target.value;
        } else if (type === 'dropshipping') {
            this.state.sticker.dropshipping = event.target.checked;
        }

        this.setState({sticker: this.state.sticker});
    }

    handleChangeSelect(event, type) {

        if (type === 'address') {
            const address = this.state.sticker.listCustomerAdress.find((row) => row.LINEA === parseInt(event.target.value));
            if (address) {
                this.state.sticker.name = (!Utils.isFullEmpty(this.state.sticker.tradename) ? this.state.sticker.tradename : (!Utils.isFullEmpty(address.NOMBRE) ? address.NOMBRE : this.state.sticker.customer));
                this.state.sticker.address = address.DIRECCION;
                this.state.sticker.cp = address.CODPOS;
                this.state.sticker.county = address.POBLACION;
                this.state.sticker.state = address.PROVINCIA;
                this.state.sticker.phone = (!Utils.isFullEmpty(address.TELEFONO) ? address.TELEFONO : (this.state.sticker.phonesDefault.length > 0 && !Utils.isFullEmpty(this.state.sticker.phonesDefault[0].TELEFONO) ? this.state.sticker.phonesDefault[0]?.TELEFONO : ''));
            }
        }

        this.setState({sticker: this.state.sticker});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.sticker !== prevProps.sticker) {
            this.setState({
                addresses: [{
                    label: '',
                    value: ''
                }, ...this.props.sticker.listCustomerAdress.map((address) => ({
                    label:(!Utils.isFullEmpty(address.NOMBRE) ? address.NOMBRE: (!Utils.isFullEmpty(this.props.sticker.tradename) ? this.props.sticker.tradename  : this.props.sticker.customer)),
                    value: address.LINEA
                }))]
            });
            this.setState({sticker: this.props.sticker});
        }
    }

    render() {

        const {show} = this.props;
        const {sticker, addresses} = this.state;

        return (
            <Modal show={show} onHide={() => this.handleClose()}>
                <Modal.Header closeButton>
                    <CModalTitle>Dirección</CModalTitle>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-4">
                        {addresses.length > 0 ?
                            <>
                                <label htmlFor="list-address" className="form-label">Direcciones</label>
                                <CFormSelect className='form-control mb-2'
                                             onChange={(e) => this.handleChangeSelect(e, 'address')}
                                             id="list-address"
                                             name="list-address"
                                             aria-label="Seleccionar dirección"
                                             options={addresses}/>
                            </>
                            : ''}
                    </div>
                    <div className="mb-3">
                        <CFormCheck label="Dropshipping" type='checkbox' id="dropshipping" name="dropshipping"
                                    checked={sticker.dropshipping} onChange={(e) => this.handleChange(e, 'dropshipping')}
                                    />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="name" className="form-label">Razón social</label>
                        <CFormInput className="form-control" type="text" id="name" name="name"
                                    value={sticker.name} onChange={(e) => this.handleChange(e, 'name')}
                                    placeholder='Razón social'/>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="address" className="form-label">Dirección</label>
                        <CFormInput className="form-control" type="text" id="address" name="address"
                                    value={sticker.address}
                                    onChange={(e) => this.handleChange(e, 'address')} placeholder='Dirección'/>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="cp" className="form-label">Código postal</label>
                        <CFormInput className="form-control" type="text" id="cp" name="cp"
                                    value={sticker.cp} onChange={(e) => this.handleChange(e, 'cp')}
                                    placeholder='Código postal'/>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="county" className="form-label">Población</label>
                        <CFormInput className="form-control" type="text" id="county" name="county"
                                    value={sticker.county}
                                    onChange={(e) => this.handleChange(e, 'county')} placeholder='Población'/>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="state" className="form-label">Provincia</label>
                        <CFormInput className="form-control" type="text" id="state" name="state"
                                    value={sticker.state} onChange={(e) => this.handleChange(e, 'state')}
                                    placeholder='Provincia'/>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="state" className="form-label">Teléfono</label>
                        <CFormInput className="form-control" type="text" id="phone" name="phone"
                                    value={sticker.phone} onChange={(e) => this.handleChange(e, 'phone')}
                                    placeholder='Teléfono'/>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => this.handleConfirmation()}>
                        Guardar
                    </Button>
                    <Button variant="secondary" onClick={() => this.handleClose()}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default Address;