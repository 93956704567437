import React, {Component} from 'react'
import {NavLink} from 'react-router-dom'
import {
    CContainer,
    CHeader,
    CHeaderBrand,
    CHeaderDivider,
    CHeaderNav,
    CHeaderToggler,
    CNavLink,
    CNavItem,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import {cilBell, cilEnvelopeOpen, cilList, cilMenu} from '@coreui/icons'
import AppBreadcrumb from "./Breadcrumb";
import {StateContext} from "../context/StateProvider";
import logo from "../../assets/brand/logo.png";
import AppHeaderDropdown from "./HeaderDropdown";

class Header extends Component {

    static contextType = StateContext;

    render() {

        const {state, updateState} = this.context;

        return (
            <CHeader position="sticky" className="mb-4">
                <CContainer fluid>
                    <CHeaderToggler
                        className="ps-1"
                        onClick={() => {
                            updateState({sidebarShow: !state.sidebarShow})
                        }}
                    >
                        <CIcon icon={cilMenu} size="lg"/>
                    </CHeaderToggler>
                    <CHeaderBrand className="mx-auto d-md-none" to="/">
                        <img src={logo} alt="Mandatelo" width="180"/>
                    </CHeaderBrand>
                    <CHeaderNav className="d-none d-md-flex me-auto">
                        {/*<CNavItem>*/}
                        {/*    <CNavLink to="/dashboard" component={NavLink}>*/}
                        {/*        Dashboard*/}
                        {/*    </CNavLink>*/}
                        {/*</CNavItem>*/}
                    </CHeaderNav>
                    <CHeaderNav>
                        {/*<CNavItem>*/}
                        {/*    <CNavLink href="#">*/}
                        {/*        <CIcon icon={cilBell} size="lg"/>*/}
                        {/*    </CNavLink>*/}
                        {/*</CNavItem>*/}
                        {/*<CNavItem>*/}
                        {/*    <CNavLink href="#">*/}
                        {/*        <CIcon icon={cilList} size="lg"/>*/}
                        {/*    </CNavLink>*/}
                        {/*</CNavItem>*/}
                        {/*<CNavItem>*/}
                        {/*    <CNavLink href="#">*/}
                        {/*        <CIcon icon={cilEnvelopeOpen} size="lg"/>*/}
                        {/*    </CNavLink>*/}
                        {/*</CNavItem>*/}
                    </CHeaderNav>
                    <CHeaderNav className="ms-3">
                        <AppHeaderDropdown />
                    </CHeaderNav>
                </CContainer>
                <CHeaderDivider/>
                <CContainer fluid>
                    <AppBreadcrumb/>
                </CContainer>
            </CHeader>
        );
    }
}

export default Header
