import React, {useContext, useEffect, useState} from 'react';
import {CButton, CCard, CCardBody, CCol, CForm, CFormInput, CSpinner} from "@coreui/react";
import ListProducts from "../components/lists/ListProducts";
import ProductService from "../services/product.service";
import ProductFactory from "../models/ProductFactory";
import {StateContext} from "../components/context/StateProvider";


const Inventory = () => {

    const {updateState} = useContext(StateContext);
    const [loading, setLoading] = useState(false);
    const [threshold, setThreshold] = useState('');

    useEffect(() => {
        return () => {
            updateState({ toasts: [] });
            updateState({ products: [] });
        };
    }, []);

    const handleChange = (event) => {
        setThreshold(event.target.value);
    }

    const handleSubmit = async (event) => {

        if (event) {
            event.preventDefault();
        }


        if (threshold !== "") {

            setLoading(true);

            await ProductService.getProductInventory(threshold).then(
                async (data) => {
                    const productMap = data.map(async (product) => {

                        let productInventory = ProductFactory.product(product.CODIGO,
                            product.BarrasModels,
                            product.NOMBRE,
                            product.UBICACION,
                            product.VENSERIE,
                            product.Art_FotoModels,
                            product.MulticamModels,
                            product.Stocks2Models,
                            null
                        );
                        productInventory.inventory = product.INVENTARIO;

                        return productInventory;
                    });

                    const productsMapFull = await Promise.all(productMap);

                    updateState({products: productsMapFull});
                    setLoading(false);
                }
            );
        }
    }

    return (
        <CCol id="inventory" md={12}>
            <CCard className="p-3">
                <CCardBody>
                    <CForm onSubmit={(e) => handleSubmit(e)} className="mb-3">
                        <div className="mb-3">
                            <label htmlFor="threshold" className="form-label">Stock</label>
                            <CFormInput className="form-control" type="text" id="threshold" name="threshold"
                                        onChange={(e) => handleChange(e)}/>
                        </div>
                        <CButton type="submit" color="primary" className="px-4 col-12">
                            {loading ?
                                <CSpinner component="span" size="sm"
                                          aria-hidden="true"/> : 'Buscar'}
                        </CButton>
                    </CForm>
                    <ListProducts updateProducts="0" selectProducts="0" print={false} inventory={true}/>
                </CCardBody>
            </CCard>
        </CCol>
    );
}

export default Inventory;