import React from 'react';
import ListProducts from '../components/lists/ListProducts'
import ProductService from "../services/product.service";
import {CButton, CCard, CCardBody, CCol, CForm, CFormInput, CSpinner} from "@coreui/react";
import {StateContext} from "../components/context/StateProvider";
import ProductFactory from "../models/ProductFactory";
import AuthService from "../services/auth.service";
import Roles from "../config/roles";


class Products extends React.Component {

    static contextType = StateContext;

    constructor(props) {
        super(props);
        this.state = {ref: '', update: "1"};
        this.productRef = React.createRef();
    }

    handleChange(event) {
        this.setState({ref: event.target.value});
    }

    componentDidMount() {
        let user = AuthService.getCurrentUser();
        if (user.roles.includes(Roles.ALMACEN.role) || user.roles.includes(Roles.SAT.role)) {
            this.setState({update: "0"});
        }
    }

    async handleSubmit(event) {

        if (event) {
            event.preventDefault();
        }

        const {updateState} = this.context;

        updateState({products:[]});

        if (this.state.ref !== "") {

            updateState({loading: true});

            await ProductService.getProducts(this.state.ref).then(
                async (data) => {
                    const productMap = data.map(async (product) => {

                        return ProductFactory.product(product.CODIGO,
                            product.BarrasModels,
                            product.NOMBRE,
                            product.UBICACION,
                            product.VENSERIE,
                            product.Art_FotoModels,
                            product.MulticamModels,
                            product.Stocks2Models,
                            product.REPONER
                        );
                    });

                    const productsMapFull = await Promise.all(productMap);
                    updateState({products: productsMapFull})
                    updateState({loading: false});
                }
            );
        } else {
            this.productRef.current.focus();
        }
    }

    componentWillUnmount() {
        const {updateState} = this.context;
        updateState({toasts: []})
        updateState({products:[]});
    }

    render() {

        const {state} = this.context;
        const {update} = this.state;

        return (
            <CCol id="place" md={12}>
                <CCard className="p-3">
                    <CCardBody>
                        <CForm onSubmit={(e) => this.handleSubmit(e)} className="mb-3">
                            <div className="mb-3">
                                <label htmlFor="ref-products" className="form-label">Producto (Referencia/Código
                                    barras/Nombre/Ubicación)</label>
                                <CFormInput ref={this.productRef} className="form-control" type="text" id="ref-products" name="ref-products" value={this.state.ref}
                                            onChange={(e) => this.handleChange(e)}/>
                            </div>
                            <CButton type="submit" color="primary" className="px-4 col-12">
                                {state.loading ?
                                    <CSpinner component="span" size="sm"
                                              aria-hidden="true"/> : 'Buscar'}
                            </CButton>
                        </CForm>
                        <ListProducts updateProducts={update} selectProducts="0"/>
                    </CCardBody>
                </CCard>
            </CCol>
        );
    }
}

export default Products;