import React from "react";
import {CButton, CCard, CCardBody, CCol, CForm, CFormInput, CFormSelect, CSpinner} from "@coreui/react";
import {StateContext} from "../components/context/StateProvider";
import SalesService from "../services/sales.service";
import ProductFactory from "../models/ProductFactory";
import Order, {OrderType} from "../models/Order";
import OrderService from "../services/order.service";
import ListOrder from "../components/lists/ListOrder";
import Utils from "../utils/Utils";
import Toasts from "../components/notificacion/Toasts";
import SocketClient from "../socket/SocketClient";


class Sales extends React.Component {

    static contextType = StateContext;

    constructor(props) {
        super(props);
        this.state = {order: ''}; // Order es el numero del pedido que se esta buscando
    }

    handleChange(event) {
        this.setState({order: event.target.value});
    }

    handleChangeYear(event) {
        const {updateState} = this.context;
        updateState({year: parseInt(event.target.value)});
    }

    componentDidMount() {

        this.interval = setInterval(() => this.checkOrderN2(null), 120000);
        this.socketClient = new SocketClient();
        this.socketClient.connect();

        this.socketClient.receiveUpdateSale(async (data) => {
            const {state, updateState} = this.context;

            if (data[0].pedido === this.state.order) {
                await this.getSales(updateState, state, true);
            }
        })
    }

    componentWillUnmount() {
        if (this.interval) {
            clearInterval(this.interval); // Limpia el intervalo al desmontar el componente
        }
        const {updateState} = this.context;
        updateState({toasts: []})
        updateState({products:[]});
        updateState({order: new Order()});
        updateState({year: Utils.getCurrentYear()});
        this.socketClient.disconnect();
    }

    handleInputFocus = () => {
        this.setState({ order: '' });
    };

    /**
     * Gestionar evento submit
     * @param event
     * @returns {Promise<void>}
     */
    async handleSubmit(event) {

        if (event) {
            event.preventDefault();
        }

        const {state, updateState} = this.context;

        if (this.state.order !== "" && Utils.checkOrder(this.state.order)) {
            updateState({loading: true});
            await this.getSales(updateState, state, false);
        } else if (this.state.order !== "" && !Utils.checkOrder(this.state.order)) {
            const toasts = Toasts.generateToast('Formato de albarán inválido: SERIE-ALBARAN', 'error');
            updateState({toasts: [...state.toasts, toasts]})
        }
    }

    /**
     * Obtener ventas en funcion de la orden
     * @param updateState
     * @param state
     * @returns {Promise<void>}
     */
    async getSales(updateState, state, updateBySocket) {
        await SalesService.getSales(this.state.order, OrderType.Albaran.valueOf(), state.year).then(
            async (data) => {

                try {
                    const {state} = this.context;
                    const stateContext = state;

                    let complete = 0;
                    let lastUpdated = null;

                    const salesMap = data.map(async (sale, index) => {

                        let state = 0;
                        let remaining = sale.UNIDADES;
                        let register = 0;
                        let registerDate = undefined;
                        let manual = false;

                        if (!Utils.checkRefProduct(sale.ARTICULO)) {

                            let refArticulo = sale.ARTICULO;

                            if (sale.DUPLICADO) {
                                refArticulo += '-' + sale.LINIA;
                            }

                            // Obtener ventas
                            const saleRegistry = await SalesService.getSalesProduct(sale.LETRA+'-'+sale.NUMERO, refArticulo, stateContext.year, 1);

                            // Comprobar ventas quedan quedan por añadir
                            if (saleRegistry.length > 0) {
                                remaining = sale.UNIDADES - saleRegistry[0].unidades;
                                register = saleRegistry[0].unidades;

                                // Cambiar el estado en funcion de los bultos
                                if (remaining === 0) {
                                    state = 1;
                                    complete += 1;
                                }

                                registerDate = saleRegistry[0].registrado;
                                manual = saleRegistry[0].manual;

                                if (lastUpdated === null || lastUpdated < new Date(saleRegistry[0].registrado)) {
                                    lastUpdated = new Date(saleRegistry[0].registrado);
                                }
                            }

                        } else {
                            sale.UNIDADES = 0;
                            remaining = 0;
                            state = 1;
                            complete += 1;
                        }

                        let barrasModels = 'BarrasModels';
                        if (stateContext.year !== Utils.getCurrentYear()) {
                            barrasModels = 'BarrasModel'+stateContext.year+'s';
                        }

                        return ProductFactory.productOrder(sale.LETRA+'-'+sale.NUMERO, sale.ARTICULO, sale.INFO_ARTICULO[barrasModels], sale.INFO_ARTICULO.NOMBRE,
                            sale.UNIDADES, sale.INFO_ARTICULO.UBICACION, sale.INFO_ARTICULO.VENSERIE, remaining, state, register, sale.DUPLICADO, sale.LINIA, registerDate, manual, index);
                    });

                    const salesMapFull = await Promise.all(salesMap);

                    await this.createOrder(lastUpdated, complete === data.length)

                    let valueFilter = '0';
                    if (complete === data.length) {
                        valueFilter = '1';
                    }

                    if (!updateBySocket) {
                        updateState({filters: [{field: 'state', operator: 'equals', value: valueFilter}]})
                    } else if(complete === data.length){
                        updateState({filters: [{field: 'state', operator: 'equals', value: valueFilter}]})
                    }

                    updateState({products: salesMapFull})
                    updateState({loading: false});

                } catch (error) {
                    const toasts = Toasts.generateToast(error.response.data.message, 'error');
                    updateState({toasts: [...state.toasts, toasts]})
                }
            }
        );
    }

    /**
     * Generar pedido
     * @param lastUpdated
     * @param complete
     * @returns {Promise<void>}
     */
    async createOrder(lastUpdated, complete) {

        const {updateState, state} = this.context;

        let order = new Order();

        order.num = this.state.order;
        order.complete = complete;

        if (lastUpdated) {
            order.setLastUpdated(lastUpdated);
        }

        // Obtener personas asignadas al pedido
        await this.getPersonsOrder(order, state.year);

        // Comprobar si existe productos en nave 2
        await this.checkOrderN2(order);

        updateState({order: order});
    }

    /**
     * Obtener personas asignadas al pedido
     * @param order
     * @returns {Promise<void>}
     */
    async getPersonsOrder(order, year) {
        const persons = await OrderService.getPersonsOrder(this.state.order, 'ventas', year);
        if (persons.length > 0) {
            order.reviewer = (persons[0].revisado === null ? '0' : persons[0].revisado);
            order.preparer = (persons[0].preparado === null ? '0' : persons[0].preparado);
            order.camera = persons[0].camara;
            order.state = persons[0].estado;
        }
    }

    /**
     * Comprobar si tiene productos en nave 2
     * @param order
     * @returns {Promise<void>}
     */
    async checkOrderN2(order) {

        const {state, updateState} = this.context;

        let update = false;

        if (order === null) {
            order = state.order;
            update = true;
        }

        if (order.num !== null) {

            const response = await OrderService.checkOrderN2(order.num, state.year);

            if (response?.details?.existen) {

                const orderState = await OrderService.getStateOrderN2(order.num, state.year);

                if (orderState.length > 0 && (orderState[0].estado !== order.stateN2 || orderState[0].preparado !== order.preparerN2)) {
                    order.stateN2 = (orderState[0].estado === null ? '1' : orderState[0].estado);
                    order.preparerN2 = (orderState[0].preparado === null ? '0' : orderState[0].preparado);
                } else {
                    update = false;
                }

                order.n2 = true;

                if (update) {
                    updateState({order: order});
                }
            }
        }
    }

    render() {

        const {state} = this.context;

        return (
            <CCol id="sales" md={12}>
                <CCard className="p-3">
                    <CCardBody>
                        <CForm onSubmit={(e)=>this.handleSubmit(e)} className="mb-3">
                            <div className="mb-3">
                                <label htmlFor="list-year" className="form-label">Año</label>
                                <CFormSelect className='form-control mb-2'
                                             onChange={(e) => this.handleChangeYear(e)}
                                             id="list-year"
                                             name="list-year"
                                             aria-label="Seleccionar año"
                                             options={Utils.getArrayYears()}/>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="order" className="form-label">Nº albarán</label>
                                <CFormInput className="form-control" type="text" id="order" name="order"
                                            value={this.state.order}
                                            onFocus={(e)=>this.handleInputFocus(e)}
                                            onChange={(e)=>this.handleChange(e)}/>
                            </div>
                            <CButton type="submit" color="primary" className="px-4 col-12">
                                {state.loading ?
                                    <CSpinner component="span" size="sm"
                                              aria-hidden="true"/> : 'Buscar'}
                            </CButton>
                        </CForm>
                        <ListOrder nave={'1'} type={'albarán'} handle={'ventas'} registerAction={SalesService.registerSale} order={this.state.order} preparer={true} reviewer={true} camera={true} orderStateN2={false} />
                    </CCardBody>
                </CCard>
            </CCol>
        )
    }
}

export default Sales;