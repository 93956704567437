import axios from "axios";
import HostService from "./host.service";
import AuthService from "./auth.service";

class SalesService {

    /**
     * Obtener ventas
     * @param order (numero de pedido)
     * @returns {Promise<AxiosResponse<any>>}
     */
    static getSales(order, type, year) {
        return axios.get(HostService.getHost() + 'ventas/buscar?pedido=' + order+'&ejercicio='+year+'&tipo='+type, AuthService.setToken())
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log(error.message);
                throw error;
            });
    }

    /**
     * Obtener ventas registradas
     * @param order
     * @param refProduct
     * @param year
     * @returns {Promise<AxiosResponse<any>>}
     */
    static getSalesProduct(order, refProduct, year, warehouse) {
        return axios.get(HostService.getHost() + 'ventas/obtener?pedido='+order+'&referencia='+refProduct+'&ejercicio='+year+'&nave='+warehouse, AuthService.setToken())
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log(error.message);
                throw error;
            });
    }

    /**
     * Registrar venta
     * @param order
     * @param refProduct
     * @param units
     * @param registerDate
     * @param manual
     * @param year
     * @returns {Promise<AxiosResponse<any>>}
     */
    static registerSale(order, refProduct, units, registerDate, manual, year, warehouse) {
        return axios.post(HostService.getHost() + 'ventas/registrar?pedido='+order+'&referencia='+refProduct+'&ejercicio='+year, { unidades: units, registrado: registerDate, manual: manual, nave: warehouse}, AuthService.setToken())
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log(error.message);
                throw error;
            });
    }

    /**
     * Obtener pedidos nave 2
     * @returns {Promise<AxiosResponse<any>>}
     */
    static getSalesN2(year) {
        return axios.get(HostService.getHost() + 'ventas/n2/buscar'+'?ejercicio='+year, AuthService.setToken())
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log(error.message);
                throw error;
            });
    }

    /**
     * Obtener productos pedidos nave 2
     * @returns {Promise<AxiosResponse<any>>}
     */
    static getSalesProductN2(order, year) {
        return axios.get(HostService.getHost() + 'ventas/n2/obtener?pedido='+order+'&ejercicio='+year, AuthService.setToken())
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log(error.message);
                throw error;
            });
    }

    /**
     * Reinsertar pedido en lista nave 2
     * @returns {Promise<AxiosResponse<any>>}
     */
    static reInsertOrderN2(order, year) {
        return axios.get(HostService.getHost() + 'ventas/n2/recuperar?pedido='+order+'&ejercicio='+year, AuthService.setToken())
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log(error.message);
                throw error;
            });
    }

    /**
     * Comprobar series productos de pedido
     * @param order
     * @param year
     * @returns {Promise<AxiosResponse<any>>}
     */
    static checkSeriesProductsOrder(order, year) {
        return axios.get(HostService.getHost() + 'ventas/'+order+'/'+year+'/series/comprobar', AuthService.setToken())
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log(error.message);
                throw error;
            });
    }

    /**
     * Comprobar registro productos de pedido
     * @param order
     * @param year
     * @returns {Promise<AxiosResponse<any>>}
     */
    static checkProductsRegisteredOrder(order, year) {
        return axios.get(HostService.getHost() + 'ventas/'+order+'/'+year+'/productos/comprobar', AuthService.setToken())
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log(error.message);
                throw error;
            });
    }

    /**
     * Obtener pedidos sin preparar y en preparacion
     * @param year
     * @returns {Promise<AxiosResponse<any>>}
     */
    static getStatesSales (year) {
        return axios.get(HostService.getHost() + 'ventas/'+year+'/estados', AuthService.setToken())
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log(error.message);
                throw error;
            });
    }

    /**
     * Obtener estado de pedidos en rutas
     * @returns {Promise<AxiosResponse<any>>}
     */
    static getDeliveryStates(year) {
        return axios.get(HostService.getHost() + 'ventas/'+year+'/estadostv', AuthService.setToken())
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                throw error;
            });
    }
}

export default SalesService;