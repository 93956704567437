import {GridRowEditStopReasons, GridRowModes, GridToolbarContainer} from "@mui/x-data-grid";
import React from "react";
import {Button} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";


class DataGridCrudBase extends React.Component{

    /**
     * Almacenar fila
     * @param id
     * @param modelGrid
     */
    handleGridSave = (id, modelGrid) => {
        this.setState({
            [modelGrid]: {...this.state[modelGrid], [id]: {mode: GridRowModes.View}},
        });
    }

    /**
     * Editar fila
     * @param id
     * @param modelGrid
     */
    handleGridEdit = (id, stateGrid, modelGrid) => {

        this.clearGridRowEdit(stateGrid, modelGrid);

        this.setState({
            [modelGrid]: {
                ...this.state[modelGrid],
                [id]: {mode: GridRowModes.Edit},
            },
        });
    }

    /**
     * Cancelar de editar fila
     * @param id
     * @param stateGrid
     * @param modelGrid
     */
    handleGridCancel = (id, stateGrid, modelGrid) => {
        this.setState({
            [modelGrid]: {
                ...this.state[modelGrid],
                [id]: {mode: GridRowModes.View, ignoreModifications: true},
            },
        });

        const editedRow = this.state[stateGrid].find((row) => row.id === id);
        if (editedRow.isNew) {
            this.setState({
                [stateGrid]: this.state[stateGrid].filter((row) => row.id !== id),
            });
        }
    };

    /**
     * Eliminar fila
     * @param id
     * @param stateGrid
     */
    handleGridDelete(id, stateGrid, callback) {
        this.setState({
            [stateGrid]: this.state[stateGrid].filter((row) => row.id !== id),
        }, callback);
    }

    /**
     * Borrar todas las filas que se estan editando
     * @param stateGrid
     * @param modelGrid
     */
    clearGridRowEdit = (stateGrid, modelGrid) => {
        return new Promise((resolve) => {
            const keys = Object.keys(this.state[modelGrid]);

            keys.forEach(key => {
                this.state[modelGrid][key] = {mode: GridRowModes.View, ignoreModifications: true}
            });

            let newState = this.state[stateGrid].filter((row) => row.isNew !== true);

            this.setState({
                [stateGrid]: [...newState]
            }, () => {
                resolve();
            });
        });
    }

    /**
     * Gestionar que el modelo cambia
     * @param newRowModesModel
     * @param modelGrid
     */
    handleGridRowModesModelChange = (newRowModesModel, modelGrid) => {
        this.setState({
            [modelGrid]: newRowModesModel,
        });
    };

    /**
     * Gestionar cuando se termina de editar la fila
     * @param params
     * @param event
     */
    handleGridRowEditStop = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    /**
     * Generar toolbar para añadir elemento en lista
     * @param stateGrid
     * @param modelGrid
     * @param text
     * @param getModel
     * @returns {JSX.Element}
     */
    gridToolbar = (stateGrid, modelGrid, text, focus, getModel) => {
        const handleClick = async () => {

            let modelObject = getModel();

            await this.clearGridRowEdit(stateGrid, modelGrid);

            this.setState({
                [stateGrid]: [...this.state[stateGrid], modelObject]
            });

            this.setState({
                [modelGrid]: {
                    ...this.state[modelGrid],
                    [modelObject.id]: {mode: GridRowModes.Edit, fieldToFocus: focus}
                }
            });
        };

        return (
            <GridToolbarContainer>
                <Button startIcon={<AddIcon/>} className='bg-primary text-white mb-3' onClick={() => handleClick()}>
                    {text}
                </Button>
            </GridToolbarContainer>
        );
    }

    /**
     * Gestionar evento enter al editar fila
     * @param params
     * @param event
     * @param conditions
     * @returns {Promise<void>}
     */
    handleGridCellEditKeyDown = async (params, event, conditions, save) => {

        if (event.key === "Enter") {

            event.preventDefault();
            event.stopPropagation();

            await this.apiRef.current.setEditCellValue({id: params.id, field: params.field, value: event.target.value});
            let row = this.apiRef.current.getRow(params.id);
            const nextCell = await conditions(row, event, params);

            this.apiRef.current.updateRows([
                {
                    id: params.id,
                    ...row,
                },
            ]);

            if (nextCell) {
                this.nextColumn(params.id, params.field, save);
            }
        }
    };

    /**
     * Situarse en la siguiente columna
     * @param id
     * @param field
     * @param save
     */
    nextColumn(id, field, save) {
        const columns = this.apiRef.current.getAllColumns();
        const start = columns.findIndex((column) => column.field === field);

        for (let i = start + 1; i < columns.length; i++) {
            if (columns[i].editable) {
                this.apiRef.current.setCellFocus(id, columns[i].field);
                return;
            }
        }

        save(id);
    }

    /**
     * Actualizar fila
     * @param newRow
     * @param rowOld
     * @param stateObject
     * @param conditions
     * @returns {Promise<*&{isNew: boolean}>}
     */
    processGridRowUpdate = async (newRow, rowOld, stateObject, stateGrid, modelGrid, conditions) => {
        const updatedRow = {...stateObject, isNew: false};
        await conditions(stateGrid);

        return updatedRow;
    };
}

export default DataGridCrudBase;