

class Box {

    constructor(id, high, wide, long) {
        this.id = id;
        this.high = high;
        this.wide = wide;
        this.long = long;
        this.m3 =  Box.calculateM3((high * long * wide) / 1000000);
    }

    static calculateM3 (value) {
        return parseFloat(value.toFixed(4));
    }
}

export default Box;