

class VoiceUtils {

    static voices;

    /**
     * Cargar voces del navegador
     */
    static loadVoices() {
        window.speechSynthesis.onvoiceschanged = () => {
            VoiceUtils.voices = speechSynthesis.getVoices();
        };
    }

    /**
     * Generar texto con voz
     * @param text
     */
    static speak(text) {
        const speechUtterance = new SpeechSynthesisUtterance();

        if (VoiceUtils.voices === undefined) {
            VoiceUtils.voices = speechSynthesis.getVoices();
        }

        speechUtterance.voice = VoiceUtils.voices?.find(voice => {
            return voice.name === 'Microsoft Helena - Spanish (Spain)';
        });
        speechUtterance.text = text;
        speechUtterance.lang = 'es-ES';
        speechUtterance.pitch = 1;
        speechUtterance.rate = 1;
        window.speechSynthesis.speak(speechUtterance);
    }
}

export default VoiceUtils;