import axios from "axios";
import AuthService from "./auth.service";
import HostService from "./host.service";
import Delivery from "../models/Delivery";
import moment from "moment";


class DeliveryService {

    /**
     * Obtener envios
     * @param value
     * @returns {Promise<AxiosResponse<any>>}
     */
    static getShipping(value, year) {
        return axios.get(HostService.getHost() + 'rutas/buscar?reparto='+value+'&ejercicio='+year, AuthService.setToken())
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                console.log(err.message);
                throw err;
            });
    }

    /**
     * Registrar bulto
     * @param route
     * @param document
     * @param packages
     * @param year
     * @returns {Promise<AxiosResponse<any>>}
     */
    static registerBulto(route, document, packages, year, registrado) {
        return axios.post(HostService.getHost() + 'rutas/registrar?reparto='+route+'&documento='+document+'&ejercicio='+year, { bulto: packages, registrado: registrado }, AuthService.setToken())
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                console.log(err.message);
            });
    }

    /**
     * Obtener bultos
     * @param route
     * @param document
     * @param year
     * @returns {Promise<AxiosResponse<any>>}
     */
    static getBultos(route, document, year) {
        return axios.get(HostService.getHost() + 'rutas/obtener?reparto='+route+'&documento='+document+'&ejercicio='+year, AuthService.setToken())
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                console.log(err.message);
            });
    }

    /**
     * Obtener rutas
     * @param updateState
     * @param state
     * @returns {Promise<AxiosResponse<any>>}
     */
    static getDeliveries() {
        return axios.get(HostService.getHost() + 'rutas/listar', AuthService.setToken())
            .then((response) => {
                return response.data.map((delivery) => {
                    return new Delivery(delivery.id, delivery.nombre, delivery.fecha, delivery.transportista,
                        delivery.panel, delivery.asigna, delivery.barito, delivery.mostrador,
                        (delivery.bultos === null ? 0 : delivery.bultos), (delivery.peso === null ? 0 : delivery.peso),
                        (delivery.expediciones === null ? 0 : delivery.expediciones), delivery.contador);
                });
            })
            .catch((error) => {
                throw error;
            });
    }

    /**
     * Obtener datos ruta
     * @param updateState
     * @param state
     * @returns {Promise<AxiosResponse<any>>}
     */
    static getDelivery(id) {
        return axios.get(HostService.getHost() + 'rutas/datos?id='+id, AuthService.setToken())
            .then((response) => {
                return new Delivery(response.data.id, response.data.nombre, response.data.fecha, response.data.transportista,
                    response.data.panel, response.data.asigna, response.data.barito, response.data.mostrador, (response.data.bultos === null ? 0 : response.data.bultos),
                    (response.data.peso === null ? 0 : response.data.peso), (response.data.expediciones === null ? 0 : response.data.expediciones), response.data.contador);
            })
            .catch((error) => {
                throw error;
            });
    }

    /**
     * Crear rutas
     * @returns {Promise<AxiosResponse<any>>}
     * @param delivery
     */
    static createDelivery(delivery) {
        return axios.post(HostService.getHost() + 'rutas/crear',
            {
                'nombre': delivery.name,
                'fecha': moment(delivery.date).format('YYYY-MM-DDTHH:mm:ss'),
                'transportista': delivery.transport,
                'panel': delivery.panel,
                'asigna': delivery.asigna,
                'barito': delivery.barito,
                'mostrador': delivery.desk
            }
            , AuthService.setToken())
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                throw error;
            });
    }

    /**
     * Modificar rutas
     * @returns {Promise<AxiosResponse<any>>}
     * @param delivery
     */
    static updateDelivery(delivery, updateStickers) {
        return axios.put(HostService.getHost() + 'rutas/modificar?id='+delivery.id,
            {
                'nombre': delivery.name,
                'fecha': moment(delivery.date).format('YYYY-MM-DDTHH:mm:ss'),
                'transportista': delivery.transport,
                'panel': delivery.panel,
                'asigna': delivery.asigna,
                'barito': delivery.barito,
                'mostrador': delivery.desk,
                'bultos': delivery.packages,
                'peso': delivery.weight,
                'expediciones': delivery.expedition,
                'contador': delivery.counter,
                'modificarEtiquetas': updateStickers
            }
            , AuthService.setToken())
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                throw error;
            });
    }

    /**
     * Modificar rutas
     * @param updateState
     * @param state
     * @returns {Promise<AxiosResponse<any>>}
     */
    static deleteDelivery(id) {
        return axios.delete(HostService.getHost() + 'rutas/eliminar?id='+id
            , AuthService.setToken())
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                throw error;
            });
    }

    /**
     * Obtener transportes
     * @param updateState
     * @param state
     * @returns {Promise<AxiosResponse<any>>}
     */
    static getTransports() {
        return axios.get(HostService.getHost() + 'rutas/transportes', AuthService.setToken())
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                throw error;
            });
    }
}


export default DeliveryService;