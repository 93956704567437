import axios from "axios";
import HostService from "./host.service";
import AuthService from "./auth.service";

class AsignaService {

    /**
     * Obtener etiqueta de asigna
     * @param reference
     * @returns {Promise<AxiosResponse<any>>}
     */
    static getSticker(reference) {
        return axios.get(HostService.getHost() + 'asigna/etiqueta?referencia=' + reference, AuthService.setToken())
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log(error.message);
                throw error;
            });
    }

    /**
     * Registrar envío en asigna
     * @param sticker
     * @returns {Promise<AxiosResponse<any>>}
     */
    static registerDelivery(sticker) {
        return axios.post(HostService.getHost() + 'asigna/envio',
            {
                "referencia": window.env.ASIGNA_PREFIX + sticker.serie + '-' + sticker.order,
                "cp": sticker.cp,
                "direccion": sticker.address,
                "email": "prueba",
                "nif": "prueba",
                "nombre": sticker.name,
                "tel": sticker.phone,
                "bultos": sticker.packages,
                "comentario": sticker.comment
            }, AuthService.setToken())
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log(error.message);
                throw error;
            });
    }
}

export default AsignaService;