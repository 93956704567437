

class OrderStates {

    static states = ["Sin preparar", "En preparación", "Preparado", "En revisión"];

    static getStatesJson() {
        return [{label: this.states[0], value: 1}, {label: this.states[1], value: 2}, {label: this.states[3], value: 4}, {label: this.states[2], value: 3}];
    }

    static getState(state) {
        return this.states[state-1];
    }

}

export default OrderStates;