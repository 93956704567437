import React from 'react';
import { usePermission } from 'react-permission-role';

const withPermissionHook = (WrappedComponent) => {
    return (props) => {
        const { setUser } = usePermission();

        // Pasamos la función setUser como prop al componente de clase
        return <WrappedComponent {...props} setUser={setUser} />;
    };
};

export default withPermissionHook;