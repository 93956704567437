import React from "react";
import {StateContext} from "../context/StateProvider";
import Utils from "../../utils/Utils";

class PrintDelivery extends React.Component {

    static contextType = StateContext;

    render() {

        const {print, delivery} = this.props;

        return (<div className='hidden'>
                <div className='row m-1'>
                    <div className="col-3 col ">
                        <div className="col-12 d-inline-flex align-items-center">
                            <label htmlFor="preparer"
                                   className="form-label mb-1 me-2">Ruta:</label>
                            <div className="form-text mt-0 mb-1">{delivery.id}</div>
                        </div>
                        <div className="col-12 d-inline-flex align-items-center">
                            <label htmlFor="preparer"
                                   className="form-label mb-1 me-2">Nombre:</label>
                            <div className="form-text mt-0 mb-1">{delivery.name}</div>
                        </div>
                        <div className="col-12 d-inline-flex align-items-center">
                            <label htmlFor="preparer"
                                   className="form-label mb-1 me-2">Fecha:</label>
                            <div
                                className="form-text mt-0 mb-1">{Utils.formatDateWithoutHour(Date.parse(delivery.date))}</div>
                        </div>
                    </div>
                    <div className="col-3 col align-items-center">
                        <div className="col-12 d-inline-flex align-items-center">
                            <label htmlFor="preparer"
                                   className="form-label mb-1 me-2">Bultos totales:</label>
                            <div className="form-text mt-0 mb-1">{delivery.packages}</div>
                        </div>
                        <div className="col-12 d-inline-flex align-items-center">
                            <label htmlFor="preparer"
                                   className="form-label mb-1 me-2">Expediciones:</label>
                            <div className="form-text mt-0 mb-1">{delivery.expedition}</div>
                        </div>
                        {delivery.barito ?
                            <div className="col-12 d-inline-flex align-items-center">
                                <label htmlFor="preparer"
                                       className="form-label mb-1 me-2">M3 totales:</label>
                                <div className="form-text mt-0 mb-1">{delivery.weight}</div>
                            </div>
                            : ''}
                    </div>
                </div>
                <table className="table">
                    <thead>
                    <tr>
                        <th scope="col">Documento</th>
                        <th scope="col">Id cliente</th>
                        <th scope="col">Nombre cliente</th>
                        <th scope="col">Forma pago</th>
                        <th scope="col">Bultos</th>
                        <th scope="col">Agencia</th>
                        {delivery?.barito ? <th scope="col">M3</th> : ''}
                    </tr>
                    </thead>
                    <tbody>
                    {print.map(function (index) {
                        return (
                            <PackageDeliveryPrint key={index.id} print={index} delivery={delivery}/>
                        )
                    })}
                    </tbody>
                </table>
            </div>
        );
    }
}

class PackageDeliveryPrint extends React.Component {

    render() {

        const {print, delivery} = this.props;

        return (
            <tr>
                <td>{print.serie}-{print.order}</td>
                <td>{print.id_customer}</td>
                <td>{print.customer}</td>
                <td>{print.way_pay}</td>
                <td>{print.packages}</td>
                <td>{print.nameAgency}</td>
                {delivery?.barito ? <td>{print.weight}</td> : ''}
            </tr>
        );
    }
}

export default PrintDelivery;