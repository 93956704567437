import {io} from "socket.io-client";
import HostService from "../services/host.service";

class SocketClient {
    constructor() {
        console.log('Conectando al socket...:' + HostService.getHost());

        const socketOptions = {
            reconnection: true, // Habilita los reintentos de conexión
            reconnectionAttempts: 10, // Número máximo de reintentos
            reconnectionDelay: 1000, // Intervalo entre reintentos (en milisegundos)
            reconnectionDelayMax: 5000, // Intervalo máximo entre reintentos
            randomizationFactor: 0.5, // Factor de aleatoriedad en el intervalo entre reintentos
        };

        this.socket = io(HostService.getHost(), socketOptions);
        this.keepAliveInterval = setInterval(() => this.keepAlive(), 10000);
    }

    connect() {
        this.socket.connect();
    }

    disconnect() {
        this.socket.disconnect();
        this.removeKeepAlive();
    }

    keepAlive() {
        this.socket.emit("keep-alive");
    }

    removeKeepAlive() {
        clearInterval(this.keepAliveInterval);
    }

    receiveUpdateVersion(callback) {
        this.socket.on('updateApp', callback);
    }

    receiveUpdateSale(callback) {
        this.socket.on('updateSale', callback);
    }

    receiveUpdatePurchase(callback) {
        this.socket.on('updatePurchase', callback);
    }
}


export default SocketClient;