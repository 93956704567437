import React from 'react'
import Header from "../template/Header";
import AppFooter from "../template/Footer";
import AppContent from "../template/Content";
import AppSidebar from "../template/Sidebar";

export default class DefaultLayout extends React.Component {
    render() {
        return (
            <div>
                <AppSidebar />
                <div className="wrapper d-flex flex-column min-vh-100 bg-light">
                    <Header />
                    <div className="body flex-grow-1 px-3">
                        <AppContent />
                    </div>
                    <AppFooter />
                </div>
            </div>
        )
    }
}
