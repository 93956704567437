import axios from "axios";
import HostService from "./host.service";
import AuthService from "./auth.service";

class PurchaseService {

    /**
     * Obtener compras
     * @param value
     * @returns {Promise<AxiosResponse<any>>}
     */
    static getPurchases(order, year) {
        return axios.get(HostService.getHost() + 'compras/buscar?pedido='+order+'&ejercicio='+year, AuthService.setToken())
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                console.log(err.message);
            });
    }

    /**
     * Obtener compras
     * @param order
     * @param refProduct
     * @param year
     * @returns {Promise<AxiosResponse<any>>}
     */
    static getPurchasesProduct(order, refProduct, year) {
        return axios.get(HostService.getHost() + 'compras/obtener?pedido='+order+'&referencia='+refProduct+'&ejercicio='+year, AuthService.setToken())
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                console.log(err.message);
            });
    }

    /**
     * Registrar compra
     * @param order
     * @param refProduct
     * @param units
     * @param registerDate
     * @param manual
     * @param year
     * @returns {Promise<AxiosResponse<any>>}
     */
    static registerPurchase(order, refProduct, units, registerDate, manual, year) {
        return axios.post(HostService.getHost() + 'compras/registrar?pedido='+order+'&referencia='+refProduct+'&ejercicio='+year, { unidades: units, registrado: registerDate, manual: manual }, AuthService.setToken())
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                console.log(err.message);
            });
    }
}

export default PurchaseService;