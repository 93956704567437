import React from "react";
import {CButton, CCard, CCardBody, CCol, CForm, CFormInput, CFormSelect, CSpinner} from "@coreui/react";
import {StateContext} from "../components/context/StateProvider";
import PurchaseService from "../services/purchase.service";
import ProductFactory from "../models/ProductFactory";
import OrderService from "../services/order.service";
import Order from "../models/Order";
import ListOrder from "../components/lists/ListOrder";
import Utils from "../utils/Utils";
import SocketClient from "../socket/SocketClient";


class Purchases extends React.Component {

    static contextType = StateContext;

    constructor(props) {
        super(props);
        this.state = {order: ''};
    }

    handleChange(event) {
        this.setState({order: event.target.value});
    }

    handleChangeYear(event) {
        const {updateState} = this.context;
        updateState({year: parseInt(event.target.value)});
    }

    componentDidMount() {

        this.socketClient = new SocketClient();
        this.socketClient.connect();

        this.socketClient.receiveUpdatePurchase(async (data) => {
            const {updateState, state} = this.context;

            if (data[0].pedido === this.state.order) {
                await this.getPurchases(updateState, false, state);
            }
        })
    }

    componentWillUnmount() {
        const {updateState} = this.context;
        updateState({toasts: []})
        updateState({products:[]});
        updateState({year: Utils.getCurrentYear()});
        this.socketClient.disconnect();
    }

    handleInputFocus = () => {
        this.setState({ order: '' });
    };

    /**
     * Gestionar evento submit
     * @param event
     * @returns {Promise<void>}
     */
    async handleSubmit(event) {

        if (event) {
            event.preventDefault();
        }

        const {updateState, state} = this.context;

        updateState({products:[]});

        if (this.state.order !== "") {

            updateState({loading: true});

            await this.getPurchases(updateState, true, state);
        }
    }

    /**
     * Obtener compras en funcion de la orden
     * @param updateState
     * @param resetFilter
     * @param state
     * @returns {Promise<void>}
     */
    async getPurchases(updateState, resetFilter, state) {
        await PurchaseService.getPurchases(this.state.order, state.year).then(
            async (data) => {

                const {state} = this.context;
                const stateContext = state;

                let complete = 0;
                let lastUpdated = null;
                let order = new Order();

                const purchasesMap = data.map(async (purchase, index) => {

                    let refArticulo = purchase.ARTICULO;

                    if (purchase.DUPLICADO) {
                        refArticulo += '-' + purchase.LINIA;
                    }

                    // Obtener compras
                    const purchaseRegistry = await PurchaseService.getPurchasesProduct(purchase.NUMERO, refArticulo, stateContext.year);

                    let state = 0;
                    let remaining = purchase.UNIDADES;
                    let register = 0;
                    let registerDate = undefined;
                    let manual = false;

                    if (!Utils.checkRefProduct(purchase.ARTICULO)) {

                        // Comprobar compras quedan quedan por añadir
                        if (purchaseRegistry.length > 0) {

                            remaining = purchase.UNIDADES - purchaseRegistry[0].unidades;
                            register = purchaseRegistry[0].unidades;

                            // Cambiar el estado en funcion de los bultos
                            if (remaining === 0) {
                                state = 1;
                            }

                            registerDate = purchaseRegistry[0].registrado;
                            manual = purchaseRegistry[0].manual;

                            if (lastUpdated === null || lastUpdated < new Date(purchaseRegistry[0].registrado)) {
                                lastUpdated = new Date(purchaseRegistry[0].registrado);
                            }
                        }

                    } else {
                        purchase.UNIDADES = 0;
                        remaining = 0;
                        state = 1;
                        complete += 1;
                    }

                    let barrasModels = 'BarrasModels';
                    if (stateContext.year !== Utils.getCurrentYear()) {
                        barrasModels = 'BarrasModel'+stateContext.year+'s';
                    }

                    return ProductFactory.productOrder(purchase.NUMERO, purchase.ARTICULO, purchase.INFO_ARTICULO[barrasModels], purchase.INFO_ARTICULO.NOMBRE,
                        purchase.UNIDADES, purchase.INFO_ARTICULO.UBICACION, purchase.INFO_ARTICULO.VENSERIE, remaining, state, register, purchase.DUPLICADO, purchase.LINIA, registerDate, manual, index);
                });

                const purchasesMapFull = await Promise.all(purchasesMap);
                const persons = await OrderService.getPersonsOrder(this.state.order, 'compras', state.year);

                if (persons.length > 0) {
                    order.reviewer = (persons[0].revisado === null ? '0' : persons[0].revisado);
                    order.preparer = (persons[0].preparado === null ? '0' : persons[0].preparado);
                }

                order.num = this.state.order;
                let valueFilter = '0';

                if (complete === data.length) {
                    order.complete = true;
                    valueFilter = '1';
                }

                if(resetFilter) {
                    updateState({filters: [{field: 'state', operator: 'equals', value: valueFilter}]})
                }

                if (lastUpdated) {
                    order.setLastUpdated(lastUpdated);
                }

                updateState({order: order});
                updateState({products: purchasesMapFull})
                updateState({loading: false});
            }
        );
    }

    render() {

        const {state} = this.context;

        return (
            <CCol id="purchase" md={12}>
                <CCard className="p-3">
                    <CCardBody>
                        <CForm onSubmit={(e)=>this.handleSubmit(e)} className="mb-3">
                            <div className="mb-3">
                                <label htmlFor="list-year" className="form-label">Año</label>
                                <CFormSelect className='form-control mb-2'
                                             onChange={(e) => this.handleChangeYear(e)}
                                             id="list-year"
                                             name="list-year"
                                             aria-label="Seleccionar año"
                                             options={Utils.getArrayYears()}/>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="order" className="form-label">Nº pedido</label>
                                <CFormInput className="form-control" type="text" id="order" name="order"
                                            value={this.state.order}
                                            onFocus={(e)=>this.handleInputFocus(e)}
                                            onChange={(e)=>this.handleChange(e)}/>
                            </div>
                            <CButton type="submit" color="primary" className="px-4 col-12">
                                {state.loading ?
                                    <CSpinner component="span" size="sm"
                                              aria-hidden="true"/> : 'Buscar'}
                            </CButton>
                        </CForm>
                        <ListOrder type={'pedido'} handle={'compras'} registerAction={PurchaseService.registerPurchase} order={this.state.order} preparer={true} reviewer={true} camera={false} orderStateN2={false} />
                    </CCardBody>
                </CCard>
            </CCol>
        )
    }
}

export default Purchases;