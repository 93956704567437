
class Delivery {

    constructor(id, name, date, transport, panel, asigna, barito, desk, packages, weight, expedition, counter) {
        this.id = id;
        this.name = name;
        this.date = date;
        this.transport = transport;
        this.panel = (panel !== undefined ? panel : false);
        this.asigna = (asigna !== undefined ? asigna : false);
        this.barito = (barito !== undefined ? barito : false);
        this.desk = (desk !== undefined ? desk : false);
        this.packages = packages;
        this.weight = weight;
        this.expedition = expedition;
        this.counter = counter;
        this.isNew = false;
    }
}

export default Delivery;